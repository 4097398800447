
<dx-data-grid
  id="partsgrid"
  [dataSource]="productionParts"
  [showBorders]="true"
  [allowColumnResizing]="true"
  [allowColumnReordering]="true"
  [columnAutoWidth]="true"
  style=" height: 400px;"
>

  <dxo-sorting mode="multiple"></dxo-sorting>
  <dxo-selection mode="single"></dxo-selection>
  <dxo-filter-row [visible]="true"></dxo-filter-row>

  <dxo-scrolling columnRenderingMode="virtual" mode="infinite"></dxo-scrolling>
  <dxo-paging [enabled]="false"></dxo-paging>

  <dxi-column
    caption="parent"
    dataField="productionPartId.parentItem"
    dataType="string"
    [sortIndex]="2"
    sortOrder="asc"
  ></dxi-column>

  <dxi-column
    caption="item"
    dataField="productionPartId.productionItemPart"
    dataType="string"

  ></dxi-column>
  <dxi-column
    caption="level"
    dataField="productionPartId.level"
    dataType="number"
    [sortIndex]="1"
    sortOrder="asc"
  >
  </dxi-column>

  <dxi-column
    caption="description"
    dataField="description"
    dataType="string"
  ></dxi-column>

  <dxi-column
    caption="amountUsed"
    dataField="amountUsed"
    dataType="number"
  >
  </dxi-column>
</dx-data-grid>

