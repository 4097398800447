<h1 class="display-6 mb-3">
  Version {{ version?.description }} für
  {{ version?.advertisingMedium.name }} vom Typ
  {{ version?.advertisingMedium.dtype }}
</h1>
<div class="row">
  <div class="col-lg-5 text-center">
    <h2 class="fs-5">Verfügbare Produkte</h2>
    <small>Können über den globalen Filter gefiltert werden</small>
    <div role="region" tabindex="0" aria-label="Tabelle: Verfügbare Angebote">
      <dx-data-grid
        #availableProducts
        id="availableProducts"
        [dataSource]="products"
        [showBorders]="true"
        [columnAutoWidth]="true"
        class="pt-3"
      >
        <dxo-filter-row [visible]="true"></dxo-filter-row>
        <dxo-header-filter [visible]="true"></dxo-header-filter>
        <dxo-filter-panel [visible]="true"></dxo-filter-panel>
        <dxo-search-panel [visible]="true"></dxo-search-panel>
        <dxo-selection
          mode="multiple"
          selectAllMode="allPages"
          showCheckBoxesMode="always"
        ></dxo-selection>
        <dxi-column caption="Nr" dataField="id" dataType="string"></dxi-column>
        <dxi-column
          caption="Name"
          dataField="description"
          dataType="string"
        ></dxi-column>
        <dxi-column
          caption="Neuprodukt"
          dataField="neu"
          dataType="boolean"
        ></dxi-column>
        <dxi-column
          caption="discontinued"
          dataField="discontinued"
          dataType="boolean"
        ></dxi-column>

        <dxo-paging [pageSize]="8"></dxo-paging>
        <dxo-pager
          [showPageSizeSelector]="true"
          [allowedPageSizes]="[8, 10, 15]"
          [showNavigationButtons]="true"
        ></dxo-pager>
      </dx-data-grid>
    </div>
  </div>

  <div class="col-1 my-auto">
    <div class="text-center">
      <button
        (click)="moveSelectionToVersion()"
        [disabled]="this.version?.sealed"
        class="btn btn-sm btn-outline-secondary"
        aria-label="Ausgewählte Angebote hinzufügen"
      >
        <i class="bi bi-caret-right-fill" aria-hidden="true"></i>
      </button>
      <br />
      <br />
      <button
        (click)="removeSelectionFromVersion()"
        [disabled]="this.version?.sealed"
        class="btn btn-sm btn-outline-secondary"
        aria-label="Ausgewählte Angebote entfernen"
      >
        <i class="bi bi-caret-left-fill" aria-hidden="true"></i>
      </button>
    </div>
  </div>

  <div class="col-lg-6 text-center">
    <h2 class="fs-5">In der Version enthaltene Produkte</h2>
    <small>&nbsp;</small>
    <div role="region" tabindex="0" aria-label="Tabelle: Hinzugefügte Angebote">
      <dx-data-grid
        #versionProductGrid
        id="versionProductGrid"
        [dataSource]="dataSource"
        [showBorders]="true"
        [columnAutoWidth]="true"
        [allowColumnResizing]="true"
        class="pt-3"
      >
        <dxo-editing [allowUpdating]="true" mode="batch"></dxo-editing>
        <dxo-filter-row
          [visible]="true"
          [showOperationChooser]="false"
        ></dxo-filter-row>
        <dxo-header-filter [visible]="true"></dxo-header-filter>
        <dxo-selection
          mode="multiple"
          selectAllMode="allPages"
          showCheckBoxesMode="always"
        ></dxo-selection>
        <dxi-column
          caption="Product"
          dataField="product.description"
          dataType="string"
          [allowEditing]="false"
        ></dxi-column>
        <dxi-column
          caption="Nr"
          dataField="product.id"
          dataType="string"
          [allowEditing]="false"
        ></dxi-column>
        <dxi-column
          caption="grossPrice"
          dataField="product.grossPrice"
          dataType="number"
          [allowEditing]="false"
        ></dxi-column>
        <dxi-column
          caption="Price"
          dataField="product.price"
          dataType="number"
          [allowEditing]="false"
        ></dxi-column>
        <dxi-column
          caption="nmPrice"
          dataField="productPrice"
          dataType="number"
          [allowEditing]="true"
        ></dxi-column>
        <dxi-column
          caption="nmSalesRank"
          dataField="productSalesRank"
          dataType="number"
          [allowEditing]="true"
        ></dxi-column>
        <dxi-column
          caption="nmSpread"
          dataField="productSpread"
          dataType="number"
          [allowEditing]="true"
        ></dxi-column>
        <dxi-column
          caption="nmGoal"
          dataField="quantityGoal"
          dataType="number"
          [allowEditing]="true"
        ></dxi-column>
        <dxi-column
          caption="nmPlanned"
          dataField="quantityPlanned"
          dataType="number"
          [allowEditing]="true"
        ></dxi-column>
        <dxi-column
          caption="nmPotential"
          dataField="quantityPotential"
          dataType="number"
          [allowEditing]="true"
        ></dxi-column>

        <dxo-paging [pageSize]="8"></dxo-paging>
        <dxo-pager
          [showPageSizeSelector]="true"
          [allowedPageSizes]="[8, 10, 15]"
          [showNavigationButtons]="true"
        ></dxo-pager>
      </dx-data-grid>
    </div>
  </div>
</div>
