export enum AdvertisingMediumLanguage {
  DE = 'DE',
  CH = 'CH',
  AT = 'AT',
  FR = 'FR',
}

export enum AdvertisingMediumStatus {
  GEPLANT = 'GEPLANT',
  LAUFEND = 'LAUFEND',
  ABGESCHLOSSEN = 'ABGESCHLOSSEN'
}

export enum EventOrtArt {
  HOTEL = 'HOTEL',
  SHOWROOM = 'SHOWROOM',
  SHOWROOM_FREMD = 'SHOWROOM_FREMD',
  SHOWROOM_EIGEN = 'SHOWROOM_EIGEN',
  OUTLET = 'OUTLET',
  PLANUNGSORT = 'PLANUNGSORT'
}

export enum EventOrtStatus {
  AKTIV = 'AKTIV',
  GEPLANT = 'GEPLANT',
  VERALTET = 'VERALTET'
}
