import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {KpiChartDTO, KpiLineChartDTO} from '../../../domain/kpi.mpodel';
import {KpiService} from '../../../services/kpi.service';
import {LinesSeriesOption} from 'echarts';
import {formatCurrency, NgFor} from '@angular/common';
import {NgxEchartsDirective, NgxEchartsModule, provideEcharts} from 'ngx-echarts';
import {FormsModule} from '@angular/forms';

@Component({
    selector: 'am-line-chart-compare',
    templateUrl: './am-line-chart-compare.component.html',
    styleUrls: ['./am-line-chart-compare.component.scss'],
    standalone: true,
  imports: [
    NgxEchartsDirective,
    FormsModule,
    NgFor,
  ],
  providers: [
    provideEcharts(),
  ]
})
export class AmLineChartCompareComponent  implements OnChanges{
  @Input() kpiLineChartDTO: KpiLineChartDTO | undefined;

  public kpiOptions:any;
  public ready = false;
  constructor(private kpiService: KpiService) {
  }

  ngOnChanges(changes: SimpleChanges): void {

    const lineCharttDTO: KpiLineChartDTO = changes['kpiLineChartDTO'].currentValue;
    if(lineCharttDTO=== undefined){
      return
    }
    lineCharttDTO.kpiSeries.forEach(value => {
      if (value.color === 'palette') {
        delete value.color;
      }
    });


    // @ts-ignore
    lineCharttDTO.kpiSeries[1]['markLine']=
        {
          width:2,
          data:
          [
          {
              name: 'average actual',
              type: 'average',
             label:{formatter: '{b}',distance:[0,0]}
          },
          { name:'start',xAxis: lineCharttDTO.advertisingMedium.start, label:{rotate:90,formatter: '{b}',distance:[0,0]} },
            { name:'end',xAxis: lineCharttDTO.advertisingMedium.end , label:{rotate:90,formatter: '{b}',distance:[0,0]}}
        ]
        }
    // @ts-ignore
    lineCharttDTO.kpiSeries[0]['markLine']=
        {
          width:2,
          data:
              [
                {
                  name: 'average predecessor',
                  type: 'average',
                  label:{formatter: '{b}',distance:[0,0]}
                }
              ]
        }
    const o: any[] = lineCharttDTO.kpiSeries;
    this.kpiOptions = {
      // title: {
      //   text: `${lineCharttDTO.kpiName}`
      // },
      legend: {},
      // tooltip: {
      //   trigger: 'item',
      //   formatter: function (params: any) {
      //     let out=''
      //     if(params.componentType==='series'){
      //       //params.forEach( (p: { seriesName: string; value: string[]; })  => {
      //       out=out+params.seriesName + ': ' +  formatCurrency( +params.value[1],'de','€', 'EUR','2.0-0') + '<br>'
      //       //})
      //     } else if (params.componentType==='markLine'){
      //       out=out+params.name
      //     }
      //     return out;
      //   },
      //   axisPointer: {
      //     animation: false
      //   }
      //
      // },
      tooltip: [
        {
          trigger: 'axis',
          formatter: function (params: any) {
            let xdate=params[0].axisValueLabel;
            let out = xdate.substring(0, 10) + '<br>';
            // @ts-ignore
            params.forEach(param => {
              if (param.componentType === 'series' && param.seriesName!=='medium' && !(<string>param.seriesName)?.startsWith("xxx_")) {
                if(param.componentSubType==='bar'){
                  out = out + param.name + ': ' + param.seriesName.replace('medium:','') + ': ' + formatCurrency(+param.value[1], 'de', '€', 'EUR', '2.0-0') + '<br>'

                } else {
                  out = out + param.seriesName + ': ' + formatCurrency(+param.value[1], 'de', '€', 'EUR', '2.0-0') + '<br>'
                }
              } else if (param.componentType === 'markLine') {
                out = out + param.name
              }
            })

            return out;
          }}],
      dataset: {
        // Provide a set of data.
        //source: data
      },
      // Declare an x-axis (category axis).
      // The category map the first column in the dataset by default.
      xAxis: {
        type: 'time'
      },
      // Declare a y-axis (value axis).
      yAxis: { gridIndex: 0 },

      grid: {
        top: 100,
        left: 100,
        right: 150,
        bottom: 100
      },

      // Declare several 'bar' series,
      // every series will auto-map to each column by default.
      dataZoom: [
        {
          id: 'dataZoomX',
          type: 'slider',
          xAxisIndex: [0],
          filterMode: 'empty',
          showDataShadow: false
        }
      ],
      series: o
    };


  this.ready = true;
  // @ts-ignore
  // const chart1 = echarts.init(document.getElementById("chart1"));
  // chart1.setOption(this.options);
}
initOpts = {
  renderer: 'svg',
  width: 300,
  height: 300,
};

}
