<h1 class="display-6">Products / KPIs</h1>
<product-kpi-chart [productId]="selectedProductId"/>

<dx-data-grid
  id="rennerGrid"
  [dataSource]="dataSource"
  [showBorders]="true"
  [allowColumnResizing]="true"
  [allowColumnReordering]="true"
  [columnAutoWidth]="true"
  (onSelectionChanged)="selectionChanged($event)"
>
  <dxo-sorting mode="single"></dxo-sorting>
  <dxo-selection mode="single"></dxo-selection>
  <dxo-filter-row [visible]="true"></dxo-filter-row>
  <dxo-header-filter [visible]="true"></dxo-header-filter>
  <dxo-filter-panel [visible]="true"></dxo-filter-panel>
  <dxo-search-panel
    [visible]="true"
    [width]="240"
    placeholder="Suche..."
  ></dxo-search-panel>
  <dxo-column-chooser [enabled]="true"></dxo-column-chooser>
  <dxo-state-storing [enabled]="true" storageKey="rennerGrid" type="sessionStorage"></dxo-state-storing>

  <dxo-editing [allowUpdating]="true"
               [useIcons]="true" mode="popup">
  </dxo-editing>

  <dxo-paging [pageSize]="8"></dxo-paging>
  <dxo-pager
    [showPageSizeSelector]="true"
    [allowedPageSizes]="[8, 10, 15]"
  ></dxo-pager>

  <dxi-column
    dataField="productId"
    caption="Produkt Id"
  >
  </dxi-column>

  <dxi-column
    dataField="productName"
    caption="Produkt Beschreibung"
  >
  </dxi-column>

  <dxi-column
    dataField="kpiDate"
    caption="KPI Date"
    dataType="date"
    [format]="'dd.MM.yyyy'"
  >
  </dxi-column>

  <dxi-column
    dataField="stock"
    caption="Stock"
  >
  </dxi-column>

  <dxi-column
    dataField="range"
    caption="range"
  >
  </dxi-column>

  <dxi-column
    dataField="sales"
    caption="sales"
    [format]="{ style: 'currency', currency: 'EUR' }"
  >
  </dxi-column>

  <dxi-column
    dataField="salesYtd"
    caption="salesYtd"
    [format]="{ style: 'currency', currency: 'EUR' }"
  >
  </dxi-column>

  <dxi-column
    dataField="salesPlaned"
    caption="salesPlaned"
    [format]="{ style: 'currency', currency: 'EUR' }"
  >
  </dxi-column>

  <dxi-column
    dataField="salesPlanedYtd"
    caption="salesPlanedYtd"
    [format]="{ style: 'currency', currency: 'EUR' }"
  >
  </dxi-column>

  <dxi-column
    dataField="salesRank"
    caption="Sales Rank">

  </dxi-column>

  <dxi-column
    dataField="quantity"
    caption="quantity"
  >
    <dxo-format
      type="fixedPoint"
      [precision]="1">

    </dxo-format>
  </dxi-column>

  <dxi-column
    dataField="quantityYtd"
    caption="quantityYtd"
  >
    <dxo-format
      type="fixedPoint"
      [precision]="1">

    </dxo-format>
  </dxi-column>

  <dxi-column
    dataField="quantityPlaned"
    caption="quantityPlaned"
  >
    <dxo-format
      type="fixedPoint"
      [precision]="1">

    </dxo-format>
  </dxi-column>

  <dxi-column
    dataField="quantityPlanedYtd"
    caption="quantityPlanedYtd"
  >
    <dxo-format
      type="fixedPoint"
      [precision]="1">

    </dxo-format>
  </dxi-column>

  <dxi-column
    dataField="quantityRank"
    caption="quantityRank"
  >
  </dxi-column>

</dx-data-grid>
