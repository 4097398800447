import {Component, ViewChild} from '@angular/core';
import { DxDataGridComponent, DxDataGridModule } from "devextreme-angular";
import DataSource from "devextreme/data/data_source";
import {lastValueFrom} from "rxjs";
import {ProductKpiDTO} from "../../domain/product.model";
import {ProductKpiService} from "../../services/product-kpi.service";
import {KpiChartDTO} from '../../domain/kpi.mpodel';
import { ProductKpiChartComponent } from '../charting/product-kpi-chart/product-kpi-chart.component';
import { DxoSortingModule, DxoSelectionModule, DxoFilterRowModule, DxoHeaderFilterModule, DxoFilterPanelModule, DxoSearchPanelModule, DxoColumnChooserModule, DxoStateStoringModule, DxoEditingModule, DxoPagingModule, DxoPagerModule, DxiColumnModule, DxoFormatModule } from 'devextreme-angular/ui/nested';

@Component({
    selector: 'app-renner-product',
    templateUrl: './renner-product.component.html',
    styleUrls: ['./renner-product.component.scss'],
    standalone: true,
    imports: [ProductKpiChartComponent, DxDataGridModule, DxoSortingModule, DxoSelectionModule, DxoFilterRowModule, DxoHeaderFilterModule, DxoFilterPanelModule, DxoSearchPanelModule, DxoColumnChooserModule, DxoStateStoringModule, DxoEditingModule, DxoPagingModule, DxoPagerModule, DxiColumnModule, DxoFormatModule]
})
export class RennerProductComponent {

  // @ts-ignore
  @ViewChild(DxDataGridComponent) rennerGrid: DxDataGridComponent;
  public dataSource: DataSource;
  public selectedProductId: any;

  constructor(private productKpiService: ProductKpiService) {
    this.dataSource = new DataSource({
      key: 'productId',
      load: () => {
        return lastValueFrom(this.productKpiService.getProductKpis()).then((rProducts: ProductKpiDTO[]) => {
          return {
            data:rProducts,
            totalCount: rProducts.length
          };
        }).catch((err) => {
          throw Error(err.message);
        });
      }
    })
  }

  selectionChanged(e: any) {
    if(e.selectedRowKeys.length===1){
      this.selectedProductId=e.selectedRowKeys[0];
    }
  }
}
