import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot, Router
} from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import {AuthorityEnum, GraphUser} from '../domain/user.model';
import {AuthService} from './auth.service';


@Injectable({
  providedIn: 'root'
})
export class RoleGuardService implements CanActivate {

  constructor(private appRoles: AuthService, private _router: Router) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    let authorized = false;
    const userRoles: string[] = this.appRoles.roles.value;
    const roles = route.data['roles'] as AuthorityEnum[];
    roles.forEach((role) => {
      if (userRoles.includes(role)) {
        authorized = true;
      }
    });

    if (!userRoles || userRoles.length === 0) {
      //window.alert('Token does not have roles claim. Please ensure that your account is assigned to an app role and then sign-out and sign-in again.');
      this._router.navigate(['login-page'])
      return false;
    } else if (!authorized) {
      //window.alert('You do not have access as expected role is missing. Please ensure that your account is assigned to an app role and then sign-out and sign-in again.');
      this._router.navigate(['login-page'])
      return false;
    }

    return true;
  }
}
