import {Component, OnInit} from '@angular/core';
import {AdvertisingMedium} from '../../../domain/advertisingMedium';
import {Campaign} from '../../../domain/campaign.model';
import {AdvertisingMediumService} from '../../../services/advertising-medium.service';
import {IntakeChannel, KpiChartDTO, KpiPerspective} from '../../../domain/kpi.mpodel';
import { NgIf } from '@angular/common';
import { KpiChartComponent } from '../../charting/kpi-chart.component';
@Component({
    selector: 'app-overview-pert',
    templateUrl: './overview-per.component.html',
    styleUrls: ['./overview-per.component.scss'],
    standalone: true,
    imports: [NgIf, KpiChartComponent]
})
export class OverviewPerComponent implements OnInit {

  public avm: AdvertisingMedium[] | undefined;
  public campaignLookup: Campaign[] = [];


  constructor(private advMService: AdvertisingMediumService) {
  }

  ngOnInit(): void {
    this.advMService.getAdvertisingMediums().subscribe(value => {
      this.avm=value.filter(adm => {
        return adm.dtype==='EVENT'
      });
    })
  }



  protected readonly KpiPerspective = KpiPerspective;
  protected readonly IntakeChannel = IntakeChannel;
}
