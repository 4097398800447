import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs';
import {ActiveProduct, DiscontinuedProduct, NewProduct, Product, ProductDTO} from '../domain/product.model';
import {DiscontinuedProductComponent} from '../components/discontinued-product/discontinued-product.component';
import {Version} from '../domain/version.model';

@Injectable({providedIn: 'root'})
export class ProductDetailService {

  constructor(private httpClient: HttpClient) {
  }
  getProductionParts(item: string): Observable<any[]>  {
    return this.httpClient.get<any[]>(`api/production-parts/${item}`);
  }
  getAssemblyParts(component: string): Observable<any[]>  {
    return this.httpClient.get<any[]>(`api/assembly-parts/${component}`);
  }

  getVariantst(item: string): Observable<any[]>  {
    return this.httpClient.get<any[]>(`api/variants/${item}`);
  }

  getTransfers(item: string): Observable<any[]>  {
    return this.httpClient.get<any[]>(`api/item/${item}/transfer`);
  }

  getPurchaseLine(item: string): Observable<any[]>  {
    return this.httpClient.get<any[]>(`api/item/${item}/purchase-line`);
  }
}
