import {Component, EventEmitter, Input, OnChanges, Output, signal, SimpleChanges, ViewChild} from '@angular/core';
import {ProductService} from '../../services/product-service.service';
import DataSource from 'devextreme/data/data_source';
import {lastValueFrom} from 'rxjs';
import {ProductDTO} from '../../domain/product.model';
import {GridStateService} from '../../services/grid-state.service';
import { DxDataGridComponent, DxDataGridModule } from 'devextreme-angular';
import CustomStore from "devextreme/data/custom_store";
import {EisUserGridStateDTO, GridState} from '../../domain/grid-state.model';
import {Version} from '../../domain/version.model';
import {NotifyService} from '../../services/notify.service';
import {EisUserService} from '../../services/eis-user.service';

import {ConfirmModalComponent} from '../interaction/confirm-modal/confirm-modal.component';
import { NgbModal, NgbAccordionDirective, NgbAccordionItem, NgbAccordionHeader, NgbAccordionToggle, NgbAccordionButton, NgbCollapse, NgbAccordionCollapse, NgbAccordionBody } from '@ng-bootstrap/ng-bootstrap';
import {validateAndFlattenComponentImports} from '@angular/compiler-cli/src/ngtsc/annotations/component/src/util';
import {patchToPut} from '../../helper/grid-helper';
import DevExpress from 'devextreme';
import InitNewRowEvent = DevExpress.ui.dxDataGrid.InitNewRowEvent;
import {EditorPreparingEvent} from 'devextreme/ui/data_grid';
import {User} from '../../auth.bak/app.state';
import { DxoPopupModule, DxoFormModule, DxiItemModule, DxoEditingModule, DxoScrollingModule, DxoPagingModule, DxoSortingModule, DxoSelectionModule, DxoFilterRowModule, DxoHeaderFilterModule, DxiColumnModule, DxiValidationRuleModule, DxiButtonModule, DxoLookupModule } from 'devextreme-angular/ui/nested';

@Component({
    selector: 'grid-state',
    templateUrl: './grid-state.component.html',
    styleUrls: ['./grid-state.component.scss'],
    standalone: true,
    imports: [NgbAccordionDirective, NgbAccordionItem, NgbAccordionHeader, NgbAccordionToggle, NgbAccordionButton, NgbCollapse, NgbAccordionCollapse, NgbAccordionBody, DxDataGridModule, DxoPopupModule, DxoFormModule, DxiItemModule, DxoEditingModule, DxoScrollingModule, DxoPagingModule, DxoSortingModule, DxoSelectionModule, DxoFilterRowModule, DxoHeaderFilterModule, DxiColumnModule, DxiValidationRuleModule, DxiButtonModule, DxoLookupModule]
})
export class GridStateComponent implements OnChanges{
  @Output() loadStateEvent = new EventEmitter<GridState>();
  @Input() gridKey: string| undefined;
  @Input() currentPatentState: any;

  _gridKey: string| undefined;


  // @ts-ignore
  @ViewChild(DxDataGridComponent) grid: DxDataGridComponent;
  // @ts-ignore
  @ViewChild(DxDataGridComponent) userGrid: DxDataGridComponent;

  // @ts-ignore
  public dataSource: DataSource;
  // @ts-ignore
  public userDataSource: CustomStore;

  public stateLookup: string[] = [];


  public newState={name: undefined}
  private userStateGrid: EisUserGridStateDTO[]=[];
  public purchaseUsers: User[]=[];
  public isCollapsed: any;

  constructor(private gridStateService: GridStateService, private notifyService : NotifyService, private userService: EisUserService, private modalService: NgbModal) {
    this.loadState=this.loadState.bind(this);
    this.saveCurrentState=this.saveCurrentState.bind(this);
   // this.deleteState=this.deleteState.bind(this);


  }

  ngOnChanges(changes: SimpleChanges): void {
    let tmpGridKey=undefined
    if (changes['gridKey']) tmpGridKey = changes['gridKey'].currentValue;
    if (changes['currentPatentState']) this.currentPatentState = changes['currentPatentState'].currentValue;
    if( tmpGridKey!==undefined && this._gridKey!== tmpGridKey){
      this.userService.findUsersForPurchase().subscribe(value => {
        this.purchaseUsers=value;
      })
      this._gridKey = tmpGridKey
      this.dataSource = new DataSource({
        key: ["stateName", "gridKey"] ,
        load: () => {
          return lastValueFrom(this.gridStateService.getStatesByGridName(<string>this._gridKey)).then((gridStates: GridState[]) =>{
            this.stateLookup=[]
            gridStates.forEach(value => {
              this.stateLookup.push(value.stateName);
            })
            return {
              data: gridStates,
              totalCount: gridStates.length
            };
          }).catch((err) => {
            throw Error(err.message);
          });
        },insert: (state: any) => {
          const _state = { ...state };
          // @ts-ignore
          delete _state['__KEY__'];
          return lastValueFrom(
            this.gridStateService.saveState(<string>this.gridKey,_state.stateName,this.currentPatentState)).then((newState) => {
            this.notifyService.toastSucess(`added state to user`)
            return newState;
          }).catch((err) => {
            throw err;
          });
        },remove: (key: any) => {
          return lastValueFrom(this.gridStateService.deleteState(<string>this.gridKey,key.stateName)).then(() => {
            this.notifyService.toastSucess(`deleted state`)
          }).catch((err) => {
            throw Error(err.error.message);
          });
        }
      })
      setTimeout(() =>{
      // @ts-ignore
        this.userDataSource = new CustomStore({

        key: ["uid", "statename"] ,

        load: () => {
          return lastValueFrom(this.userService.getUsersGridState()).then((userState: EisUserGridStateDTO[]) =>{
            this.userStateGrid=userState
            return {
              data: userState,
              totalCount: userState.length
            };
          }).catch((err) => {
            throw Error(err.message);
          });
        },
        update: (key: any, _new: EisUserGridStateDTO) => {
          const stateDTO = this.userStateGrid.
           find(value => {return value.uid===key['uid'] && value.statename===key['statename']})

            const _stateDTO = {...stateDTO};
          patchToPut(_stateDTO, _new);
            // @ts-ignore
            delete _stateDTO['__KEY__'];
            return lastValueFrom(this.userService.setUserGridState(<EisUserGridStateDTO>_stateDTO)).then(() => {
              this.notifyService.toastSucess(`set state for ${_stateDTO.name} to ${_stateDTO.statename}`)
            }).catch((err) => {
              throw Error(err.message);
            });
        },insert: (state: EisUserGridStateDTO) => {
            const _state = { ...state };
            // @ts-ignore
            delete _state['__KEY__'];
            return lastValueFrom(this.userService.createUserGridState(_state)).then((newState) => {
              this.notifyService.toastSucess(`added state to user`)
              return newState;
            }).catch((err) => {
              throw err;
            });
          },remove: (key: any) => {
            return lastValueFrom(this.userService.deleteUserGridState(key)).then(() => {
              this.notifyService.toastSucess(`deleted userstate`)
            }).catch((err) => {
              throw Error(err.error.message);
            });
          }

      });

      },200)
    }
  }

  getName(e: any) {
    return `${e.firstName} ${e.lastName}`
  }
  loadState(e: any) {
    e.event.preventDefault();
    const data: GridState = e.row.data;
    this.loadStateEvent.emit(data);

  }
  saveCurrentState(e: any) {
    e.event.preventDefault();
    const data: GridState = e.row.data;
    const state = this.currentPatentState;
    const name = <string>e.row.data.stateName
    const modalRef = this.modalService.open(ConfirmModalComponent, {
      backdrop: 'static',
      backdropClass: 'backdrop',
      size: 'lg'
    });
    modalRef.componentInstance.title = 'State überschreiben';
    modalRef.componentInstance.text = `Wollen Sie die Änderungen am Grid in den State ${name} speichern?`;
    modalRef.result.then(() => {

      this.gridStateService.saveState(<string>this.gridKey,name,state).subscribe(value => {
        this.notifyService.toastInfo(`state saved: ${value.stateName}`);
        e.row.data.jsonState=value.jsonState
      });
    }).catch(() => {
      this.notifyService.toastInfo(`der Vorgang wurde abgebrochen`);
    });

  }

  sendEvent(){
    this.loadStateEvent.emit(undefined);
  }

  // onNewStateClick() {
  //
  //   const state = this.currentPatentState;
  //   const name = <string><unknown>this.newState.name
  //
  //   const modalRef = this.modalService.open(ConfirmModalComponent, {
  //     backdrop: 'static',
  //     backdropClass: 'backdrop',
  //     size: 'lg'
  //   });
  //   modalRef.componentInstance.title = 'State erzeugen';
  //   modalRef.componentInstance.text = `Wollen Sie die den Zustand des Grids in den State ${name} speichern?`;
  //   modalRef.result.then(() => {
  //     this.gridStateService.saveState(<string>this.gridKey,name,state).subscribe(value => {
  //       this.grid.instance.refresh();
  //       this.notifyService.toastInfo('new state created');
  //     });
  //   }).catch(() => {
  //     this.notifyService.toastInfo(`der Vorgang wurde abgebrochen`);
  //   });
  // }
   public stateNamePattern= "^[a-zA-Z0-9_-]+$";
  // deleteState(e: any) {
  //   e.event.preventDefault();
  //   const data: GridState = e.row.data;
  //   const state = this.currentPatentState;
  //   const name = <string>e.row.data.stateName
  //   const modalRef = this.modalService.open(ConfirmModalComponent, {
  //     backdrop: 'static',
  //     backdropClass: 'backdrop',
  //     size: 'lg'
  //   });
  //   modalRef.componentInstance.title = 'delete state';
  //   modalRef.componentInstance.text = `Wollen Sie den State ${name} löschen?`;
  //   modalRef.result.then(() => {
  //     this.gridStateService.deleteState(<string>this.gridKey,name).subscribe(() => {
  //       this.grid.instance.refresh();
  //       this.notifyService.toastInfo('state deleted');
  //     });
  //   }).catch(() => {
  //     this.notifyService.toastInfo(`der Vorgang wurde abgebrochen`);
  //   });
  // }


  onInitNewRow(e: InitNewRowEvent) {
    e.data.defaultstate = false;
  }

  onEditorPreparing(e: EditorPreparingEvent) {
    if(e.parentType === "dataRow" && e.row && (e.dataField === "uid" || e.dataField === "statename")){

      if( e.row.isNewRow)
        e.editorOptions.disabled = false; //enabled only when new record
      else
        e.editorOptions.disabled = true; //disabled when existing

    }
  }
}
