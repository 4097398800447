<dbc-feedback [status]="status"></dbc-feedback>
<h1 class="display-6">New Product</h1>
<dx-data-grid
  [allowColumnResizing]="true"
  [dataSource]="dataSource"
  [showBorders]="true"
  [columnAutoWidth]="true"
  (onEditingStart)="editingStart($event)"
  (onInitNewRow)="initNewRow($event)"
  id="newProductGrid"
>
  <dxo-selection mode="single"></dxo-selection>
  <dxo-filter-row [visible]="true"></dxo-filter-row>
  <dxo-filter-panel [visible]="true"></dxo-filter-panel>
  <dxo-state-storing [enabled]="true" storageKey="new-product-grid" type="sessionStorage"></dxo-state-storing>

  <dxo-editing [allowAdding]="true" [allowDeleting]="true" [allowUpdating]="true"
               [useIcons]="true" mode="popup">
  <dxo-form [customizeItem]="customizeForm">
    <dxi-item dataField="id" readonly="true"></dxi-item>
    <dxi-item dataField="productDescription"></dxi-item>
    <dxi-item dataField="description"></dxi-item>
    <dxi-item
      dataField="price"
      [editorOptions]="{format: {type:'currency',currency:'EUR'}, placeholder: 'Preis in Euro'}"
    ></dxi-item>
    <dxi-item
      dataField="grossPrice"
      [editorOptions]="{format: {type:'currency',currency:'EUR'}, placeholder: 'Brutto Preis in Euro'}"
    ></dxi-item>
    <dxi-item dataField="comment"></dxi-item>
    <dxi-item dataField="estimatedArrival"></dxi-item>
    <dxi-item dataField="realProduct.id"
              [editorOptions]="{placeholder: 'Auswahl'}"
    ></dxi-item>
  </dxo-form>
  </dxo-editing>
  <dxi-column visible="false"
    caption="Id"
    dataField="id"
    dataType="string"
  ></dxi-column>
  <dxi-column
    caption="Produkt Beschreibung"
    dataField="productDescription"
    dataType="string"
  ></dxi-column>
  <dxi-column
    caption="Beschreibung"
    dataField="description"
    dataType="string"
  ></dxi-column>
  <dxi-column
    [format]="{ style: 'currency', currency: 'EUR' }"
    caption="Preis"
    dataField="price"
    dataType="number"
  ></dxi-column>
  <dxi-column
    [format]="{ style: 'currency', currency: 'EUR' }"
    caption="Brutto Preis"
    dataField="grossPrice"
    dataType="number"
  ></dxi-column>
  <dxi-column
    caption="Kommentar"
    dataField="comment"
    dataType="string"
  ></dxi-column>
  <dxi-column
    caption="geschätzte Ankunftszeit"
    dataField="estimatedArrival"
    dataType="date"
  ></dxi-column>

  <dxi-column
    dataField="realProduct.id"
    caption="Aktives Produkt"
  >
    <dxo-lookup [dataSource]="activeProducts" displayExpr="description" valueExpr="id"></dxo-lookup>
  </dxi-column>

  <dxo-paging [pageSize]="8"></dxo-paging>
  <dxo-pager
    [showPageSizeSelector]="true"
    [allowedPageSizes]="[8, 10, 15]"
    [showNavigationButtons]="true"
  ></dxo-pager>

</dx-data-grid>
