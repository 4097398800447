import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs';
import {Product} from '../domain/product.model';
import {Referrer} from '../domain/referrer.model';

@Injectable({
  providedIn: 'root'
})
export class ReferrerService {

  constructor(private httpClient: HttpClient) {
  }
  getReferrerByNewsletterId(id: number): Observable<Referrer[]>  {
    return this.httpClient.get<Referrer[]>(`api/newsletter/${id}/referrers`);
  }

  createReferrer(_referrer: Referrer, nlId: number | undefined): Observable<Referrer> {
    return this.httpClient.post<Referrer>(`api/newsletter/${nlId}/referrers`,_referrer);
  }

  updateReferrer(_referrer: Referrer): Observable<Referrer> {
    return this.httpClient.put<Referrer>(`api/referrers`,_referrer);
  }

  deleteReferrer(id: number): Observable<any> {
    return this.httpClient.delete(`api/referrers/${id}`);
  }

}
