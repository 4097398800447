import {Component, ViewChild} from '@angular/core';
import { DxDataGridComponent, DxDataGridModule } from "devextreme-angular";
import DataSource from "devextreme/data/data_source";
import {Status} from "../interaction/feedback/feedback.component";
import {ProductService} from "../../services/product-service.service";
import {lastValueFrom} from "rxjs";
import {ProductDTO} from "../../domain/product.model";
import { DxoSortingModule, DxoSelectionModule, DxoFilterRowModule, DxoHeaderFilterModule, DxoFilterPanelModule, DxoSearchPanelModule, DxoColumnChooserModule, DxoStateStoringModule, DxoEditingModule, DxoPagingModule, DxoPagerModule } from 'devextreme-angular/ui/nested';

@Component({
    selector: 'app-product',
    templateUrl: './product.component.html',
    styleUrls: ['./product.component.scss'],
    standalone: true,
    imports: [DxDataGridModule, DxoSortingModule, DxoSelectionModule, DxoFilterRowModule, DxoHeaderFilterModule, DxoFilterPanelModule, DxoSearchPanelModule, DxoColumnChooserModule, DxoStateStoringModule, DxoEditingModule, DxoPagingModule, DxoPagerModule]
})
export class ProductComponent {

  // @ts-ignore
  @ViewChild(DxDataGridComponent) grid: DxDataGridComponent;
  public dataSource: DataSource;
  // @ts-ignore
  public status: Status = undefined;

  constructor(private productService: ProductService) {
    this.dataSource = new DataSource({
      key: 'id',
      load: () => {
        return lastValueFrom(this.productService.getProductsDTO()).then((productDTOs: ProductDTO[]) =>{
          return {
            data: productDTOs,
            totalCount: productDTOs.length
          };
        }).catch((err) => {
          throw Error(err.message);
        });
      }
    })
  }

}
