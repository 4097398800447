import {Component, ViewChild} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Newsletter} from '../../../domain/newsletter.model';
import { DxDataGridComponent, DxDataGridModule } from 'devextreme-angular';
import DataSource from 'devextreme/data/data_source';
import {lastValueFrom} from 'rxjs';
import {Status, StatusType} from '../../interaction/feedback/feedback.component';
import {ReferrerService} from '../../../services/referrer.service';
import {Referrer} from '../../../domain/referrer.model';
import { DxoSelectionModule, DxoFilterRowModule, DxoEditingModule, DxoFormModule, DxiItemModule, DxiColumnModule, DxiValidationRuleModule } from 'devextreme-angular/ui/nested';

@Component({
    selector: 'app-nl-referrer',
    templateUrl: './nl-referrer.component.html',
    standalone: true,
    imports: [DxDataGridModule, DxoSelectionModule, DxoFilterRowModule, DxoEditingModule, DxoFormModule, DxiItemModule, DxiColumnModule, DxiValidationRuleModule]
})
export class NlReferrerComponent {
// @ts-ignore
  @ViewChild(DxDataGridComponent) referrerGrid: DxDataGridComponent;
  public dataSource: DataSource;

  rowData: Newsletter | undefined;
  constructor(private activeModal: NgbActiveModal, private referrerService: ReferrerService) {
    this.dataSource = new DataSource({
        key: 'id',
        load: () => {
          // @ts-ignore
          return lastValueFrom(this.referrerService.getReferrerByNewsletterId(this.rowData.id)).then((referrers: Referrer[]) => {
            return {
              data: referrers,
              totalCount: referrers.length
            };
          }).catch((err) => {
            throw Error(err.message);
          });
        },
        insert: (referrer : Referrer) => {
          const _referrer = {...referrer};
          // @ts-ignore
          delete _referrer['__KEY__'];
          return lastValueFrom(this.referrerService.createReferrer(_referrer, this.rowData?.id)).then((newReferrer : Referrer) => {
            //this.status = new Status('Der Newsletter wurde erfolgreich angelegt', StatusType.Success);
            return newReferrer;
          }).catch((err) => {
            throw err;
          });
        },
        update: (key: number, _new: Referrer) => {
          return this.referrerGrid.instance.byKey(key).then((referrer) => {
            const _referrer = {...referrer};
            delete _referrer['__KEY__'];
            return lastValueFrom(this.referrerService.updateReferrer(_referrer)).then(() => {
              //this.status = new Status('Die Änderungen wurde erfolgreich übernommen', StatusType.Success);

            }).catch((err) => {
              throw Error(err.message);
            });
          });
        },
        remove: (key: number) => {
          return lastValueFrom(this.referrerService.deleteReferrer(key)).then(() => {
            //this.status = new Status('Der Newsletter wurde erfolgreich gelöscht', StatusType.Success);
          }).catch((err) => {
            throw Error(err.error.message);
          });
        }
      }
    );
  }

  back() {
    this.activeModal.close('Bearbeitung der Referrer beendet!')
  }
}
