import {Component, ViewChild} from '@angular/core';
import { DxDataGridComponent, DxDataGridModule } from "devextreme-angular";
import DataSource from "devextreme/data/data_source";
import {Status} from "../../interaction/feedback/feedback.component";
import {lastValueFrom} from "rxjs";
import {EventService} from "../../../services/event.service";
import {EventOrt} from "../../../domain/eventOrt.model";
import { DxoSortingModule, DxoSelectionModule, DxoFilterRowModule, DxoHeaderFilterModule, DxoFilterPanelModule, DxoSearchPanelModule, DxoColumnChooserModule, DxoStateStoringModule, DxoEditingModule, DxoPagingModule, DxoPagerModule } from 'devextreme-angular/ui/nested';


@Component({
    selector: 'app-event-ort',
    templateUrl: './event-ort.component.html',
    styleUrls: ['./event-ort.component.scss'],
    standalone: true,
    imports: [DxDataGridModule, DxoSortingModule, DxoSelectionModule, DxoFilterRowModule, DxoHeaderFilterModule, DxoFilterPanelModule, DxoSearchPanelModule, DxoColumnChooserModule, DxoStateStoringModule, DxoEditingModule, DxoPagingModule, DxoPagerModule]
})
export class EventOrtComponent {
  // @ts-ignore
  @ViewChild(DxDataGridComponent) grid: DxDataGridComponent;
  public dataSource: DataSource;
  // @ts-ignore
  public status: Status = undefined;

  constructor(private eventService: EventService) {
    this.dataSource = new DataSource({
      key: 'id',
      load: () => {
        return lastValueFrom(this.eventService.getEventOrts()).then((eventOrts: EventOrt[]) =>{
          return {
            data: eventOrts,
            totalCount: eventOrts.length
          };
        }).catch((err) => {
          throw Error(err.message);
        });
      }
    })
  }
}
