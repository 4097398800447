

<div echarts style="height: 500px" [options]="kpiOptions"></div>
<div class="row g-3">
<div class="col form-check form-switch d-flex justify-content-center">
  <div>
    <input class="form-check-input" type="checkbox" role="switch" [ngModel]="kumulierteDarstellung" (ngModelChange)="changeKumulierteDarstellung($event)" id="flexSwitchCheckDefault">
    <label class="form-check-label" for="flexSwitchCheckDefault">Cumulative presentation</label>
  </div>
</div>

  <div  class="col btn-group" role="group" aria-label="period under review">
    <ng-container  *ngFor="let option of options; index as i">
    <input type="radio" class="btn-check"  autocomplete="off"
           [value]="option.value"
           [id]="i"
           [ngModel]="selectedOption.value"
           name="options"
           (ngModelChange)="changeBetrachtungsZeitraum($event)"
           />
    <label for="{{i}}" class="btn btn-outline-secondary">{{option.label}}</label>
    </ng-container>
  </div>
<!--  <div>-->
<!--    <input class="form-check-input" [(ngModel)]="smooth"  id="smooth">-->
<!--    <label class="form-check-label" for="smooth">Smooth</label>-->
<!--  </div>-->
</div>



