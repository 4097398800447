<dx-data-grid
  id="purchasegrid"
  [dataSource]="ds"
  [showBorders]="true"
  [allowColumnResizing]="true"
  [allowColumnReordering]="true"
  [columnAutoWidth]="true"
  style=" height: 400px;"
>

  <dxo-sorting mode="multiple"></dxo-sorting>
  <dxo-selection mode="single"></dxo-selection>
  <dxo-filter-row [visible]="true"></dxo-filter-row>

  <dxo-scrolling columnRenderingMode="virtual" mode="infinite"></dxo-scrolling>
  <dxo-paging [enabled]="false"></dxo-paging>

  <dxi-column
    caption="documentNo"
    dataField="id.documentNo"
    dataType="string"
  ></dxi-column>

  <dxi-column
    caption="lineNo"
    dataField="id.lineNo"
    dataType="string"
  ></dxi-column>

  <dxi-column
    caption="description"
    dataField="description"
    dataType="string"
  ></dxi-column>

  <dxi-column
    caption="directUnitCost"
    dataField="directUnitCost"
    dataType="number"
  ></dxi-column>

  <dxi-column
    caption="currencyUnitCost"
    dataField="currencyUnitCost"
    dataType="number"
  ></dxi-column>

  <dxi-column
    caption="requestedDate"
    dataField="requestedDate"
    dataType="date"
  ></dxi-column>

  <dxi-column
    caption="expectedDate"
    dataField="expectedDate"
    dataType="date"
  ></dxi-column>

  <dxi-column
    caption="quantity"
    dataField="quantity"
    dataType="number"
  ></dxi-column>

  <dxi-column
    caption="quantityInvoiced"
    dataField="quantityInvoiced"
    dataType="number"
  ></dxi-column>

  <dxi-column
    caption="quantityOutstanding"
    dataField="quantityOutstanding"
    dataType="number"
  ></dxi-column>

  <dxi-column
    caption="quantityReceived"
    dataField="quantityReceived"
    dataType="number"
  ></dxi-column>

  <dxi-column
    caption="unitMeasure"
    dataField="unitMeasure"
    dataType="string"
  ></dxi-column>


</dx-data-grid>
