import {bootstrapApplication} from '@angular/platform-browser';
import {AppComponent} from './app/app.component';
import {appConfig} from './app/app.config';

bootstrapApplication(AppComponent, appConfig)
  .catch((err) => console.error(err));

// /// <reference types="@angular/localize" />
//
// import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
// import { bootstrapApplication, BrowserModule } from '@angular/platform-browser';
// import {AppComponent} from './app/app.component';
// import {appConfig} from './app/app.config';
//
// import { provideHttpClient } from '@angular/common/http';
// import { AppRoutes } from './app/app.routes';
// import { ComponentsModule } from './app/components/components.module';
// import { FormsModule } from '@angular/forms';
// import { VersionProductModule } from './app/components/version-product/version-product.module';
// import { DiscontinuedProductModule } from './app/components/discontinued-product/discontinued-product.module';
// import { ChartingModule } from './app/components/charting/charting.module';
// import { importProvidersFrom } from '@angular/core';
//
// // import { AppModule } from './app/app.module';
// //
// //
// bootstrapApplication(AppComponent, {
//     providers: [
//         importProvidersFrom(BrowserModule, AppRoutes, ComponentsModule, FormsModule, VersionProductModule, DiscontinuedProductModule, ChartingModule),
//         // {
//         //   provide: MSAL_INSTANCE,
//         //   useFactory: MSALInstanceFactory
//         // },
//         // {
//         //   provide: MSAL_GUARD_CONFIG,
//         //   useFactory: MSALGuardConfigFactory
//         // },
//         // {
//         //   provide: MSAL_INTERCEPTOR_CONFIG,
//         //   useFactory: MSALInterceptorConfigFactory
//         // },
//         // MsalService,
//         // MsalGuard,
//         // MsalBroadcastService,
//         provideHttpClient()
//     ]
// })
//   .catch(err => console.error(err));
// // bootstrapApplication(AppComponent, appConfig)
// //   .catch((err) => console.error(err));
