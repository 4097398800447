import {Component, OnInit, ViewChild} from '@angular/core';
import DataSource from "devextreme/data/data_source";
import {AdvertisingMediumLanguage, AdvertisingMediumStatus} from "../../domain/enum.model";
import {AdvertisingMedium} from "../../domain/advertisingMedium";
import { Status, StatusType, FeedbackComponent } from '../interaction/feedback/feedback.component';
import {EventService} from "../../services/event.service";
import {lastValueFrom} from "rxjs";
import {Event} from "../../domain/event.model";
import { DxDataGridComponent, DxDataGridModule } from "devextreme-angular";
import {EventOrt} from "../../domain/eventOrt.model";
import {Campaign} from "../../domain/campaign.model";
import {CampaignService} from "../../services/campaign.service";
import { DxoSelectionModule, DxoFilterRowModule, DxoHeaderFilterModule, DxoFilterPanelModule, DxoSearchPanelModule, DxoColumnChooserModule, DxoStateStoringModule, DxoEditingModule, DxoFormModule, DxiItemModule, DxoLabelModule, DxiColumnModule, DxiButtonModule, DxiValidationRuleModule, DxoLookupModule, DxoMasterDetailModule, DxoPagingModule, DxoPagerModule } from 'devextreme-angular/ui/nested';
import { DxTemplateModule } from 'devextreme-angular/core';
import { CoverImageComponent } from '../cover-image/cover-image.component';
import { VersionDetailComponent } from '../version-detail/version-detail.component';


@Component({
    selector: 'app-event',
    templateUrl: './event.component.html',
    styleUrls: ['./event.component.scss'],
    standalone: true,
    imports: [FeedbackComponent, DxDataGridModule, DxoSelectionModule, DxoFilterRowModule, DxoHeaderFilterModule, DxoFilterPanelModule, DxoSearchPanelModule, DxoColumnChooserModule, DxoStateStoringModule, DxoEditingModule, DxoFormModule, DxiItemModule, DxoLabelModule, DxiColumnModule, DxiButtonModule, DxiValidationRuleModule, DxoLookupModule, DxTemplateModule, CoverImageComponent, DxoMasterDetailModule, VersionDetailComponent, DxoPagingModule, DxoPagerModule]
})
export class EventComponent implements OnInit{
  // @ts-ignore
  @ViewChild(DxDataGridComponent) eveGrid: DxDataGridComponentonent;
  public dataSource: DataSource;
  @ViewChild('uploadedImage') uploadedImageRef!: HTMLImageElement;
  public languageLookup: AdvertisingMediumLanguage[] = [AdvertisingMediumLanguage.DE, AdvertisingMediumLanguage.CH, AdvertisingMediumLanguage.AT, AdvertisingMediumLanguage.FR];
  public statusLookup: AdvertisingMediumStatus[] = [AdvertisingMediumStatus.ABGESCHLOSSEN, AdvertisingMediumStatus.GEPLANT, AdvertisingMediumStatus.LAUFEND];
  public previousAdvMLookup: AdvertisingMedium[] = [];
  public eventOrtsLookup: EventOrt[] = [];
  public campaignLookup: Campaign[] = [];
  // @ts-ignore
  public status: Status = undefined;

  constructor(private eveService: EventService, private campService: CampaignService) {
    this.dataSource = new DataSource({
      key: 'id',
      load: () => {
        return lastValueFrom(this.eveService.getEvents()).then((events: Event[]) => {
          this.previousAdvMLookup = events.sort((a, b) =>
            a.name.toLowerCase().localeCompare(b.name.toLowerCase())
          );
          this.eveService.getEventOrts().subscribe(value => {
            this.campService.getCampaigns().subscribe(value => {
              this.campaignLookup = value;
            })
            this.eventOrtsLookup = value.sort((a, b) =>
              a.name.toLowerCase().localeCompare(b.name.toLowerCase())
            );
          });
          return {
            data: events,
            totalCount: events.length
          };
        }).catch((err) => {
          throw Error(err.message);
        });
      },
      insert: (event: Event) => {
        const _event = { ...event };
        // @ts-ignore
        delete _event['__KEY__'];
        if(_event.previousAdvertisingMedium) {
          _event.previousAdvertisingMedium._class=".Event";
        }
        _event._class=".Event";
        return lastValueFrom(this.eveService.createEvent(_event)).then((newEvent) => {
          this.status = new Status('Das Event wurde erfolgreich angelegt', StatusType.Success);
          return newEvent;
        }).catch((err) => {
          throw err;
        });
      },
      update: (key: number, _new: Event) => {
        return this.eveGrid.instance.byKey(key).then((event: any) => {
          const _event = { ...event };
          // @ts-ignore
          delete _event['__KEY__'];
          if(_event.previousAdvertisingMedium) {
            _event.previousAdvertisingMedium._class=".Event";
          }
          _event._class=".Event";

          delete _event.coverImage;
          return lastValueFrom(this.eveService.updateEvent(_event)).then(() => {
            this.status = new Status('Die Änderungen wurde erfolgreich übernommen', StatusType.Success);
          }).catch((err) => {
            throw Error(err.message);
          });
        });
      },
      remove: (key: number) => {
        return lastValueFrom(this.eveService.deleteEvent(key)).then(() => {
          this.status = new Status('Das Event wurde erfolgreich gelöscht', StatusType.Success);
        }).catch((err) => {
          throw Error( err.error.message);
        });
      }
    });
  }
  ngOnInit(): void {
  }
}
