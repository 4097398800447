import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import {Observable} from "rxjs";
import {Product, ProductKpiDTO} from '../domain/product.model';
import {KpiLineChartDTO} from '../domain/kpi.mpodel';

@Injectable({providedIn: 'root'})
export class ProductKpiService {

  constructor(private httpClient: HttpClient) {}


  getProductKpis(): Observable<ProductKpiDTO[]> {
    return this.httpClient.get<ProductKpiDTO[]>(`api/product_kpis`);
  }

  getProductKpis4Product(product: Product): Observable<KpiLineChartDTO> {
    return this.httpClient.get<KpiLineChartDTO>(`api/product_kpis4chart/${product.id}`);
  }

  toggleBestellStop(product: ProductKpiDTO): Observable<any> {
    return this.httpClient.put<any>(`api/product/toggle-order-stop`,product);
  }
}
