import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {EChartsOption} from 'echarts';
import {KpiService} from '../../../services/kpi.service';
import {LinesSeriesOption} from 'echarts';
import {AdvertisingMediumService} from '../../../services/advertising-medium.service';
import {AdvertisingMedium} from '../../../domain/advertisingMedium';
import {DatePipe, formatCurrency, formatNumber, NgFor} from '@angular/common';
import {KpiChartDTO, KpiLineChartDTO, KpiLineSerie} from '../../../domain/kpi.mpodel';
import {ProductKpiService} from '../../../services/product-kpi.service';
import {Product} from '../../../domain/product.model';
import DevExpress from 'devextreme';
import {NgxEchartsDirective, NgxEchartsModule, provideEcharts} from 'ngx-echarts';
import map = DevExpress.viz.map;
import {FormsModule} from '@angular/forms';


@Component({
    selector: 'product-kpi-chart',
    templateUrl: './product-kpi-chart.component.html',
    styleUrls: ['./product-kpi-chart.component.scss'],
    standalone: true,
  imports: [
    NgxEchartsDirective,
    FormsModule,
    NgFor,
  ],
  providers: [
    provideEcharts(),
  ]
})
export class ProductKpiChartComponent implements OnChanges  {
  @Input() productId: string | undefined;
  public kpiOptions: EChartsOption = {};
  public ready = false;
  chart: KpiLineChartDTO | undefined;
  data: any[]=[]

  constructor(private kpiService: ProductKpiService) {
  }

  ngOnChanges(changes: SimpleChanges): void {

    const productId: string = changes['productId'].currentValue;
    if(productId=== undefined){
      return
    }
    // @ts-ignore
    const p:Product = {id:productId}

    // @ts-ignore
    //this.chart = {kpiSeries:[],kpiName: '2002 KPI'}
    // @ts-ignore
    //const planSeries: KpiLineSerie = {name: 'planed quantity', data: [],smooth: 0.4,   type: 'lines', seriesLayoutBy: 'row'}
    this.kpiService.getProductKpis4Product(p).subscribe(value => {
      // value.forEach(kpiDto => {
      //   // @ts-ignore
      //   planSeries.data.push([  kpiDto.kpiDate.substr(0,10),kpiDto.quantityPlaned])
      // })
      // this.chart?.kpiSeries.push(planSeries)

      this.chart=value;
      this.createChartOptions();
    })
  }
  createChartOptions(){

    const chart = this.chart;
    if(chart!== undefined) {

      chart.kpiSeries.forEach(value => {
        if(value.color==='palette'){
          delete value.color;
        }

        const v:any = value
        switch(v.group){
          case 'quantity':
            v['xAxisIndex']= 0
            v['yAxisIndex']= 0
            break
          case 'quantity_ytd':
            v['xAxisIndex']= 2
            v['yAxisIndex']= 2
            break
          case 'stock':
            v['xAxisIndex']= 4
            v['yAxisIndex']= 4
            break
          case 'sales':
            v['xAxisIndex']= 1
            v['yAxisIndex']= 1
            break
          case 'sales_ytd':
            v['xAxisIndex']= 3
            v['yAxisIndex']= 3
            break
          case 'range':
            v['xAxisIndex']= 5
            v['yAxisIndex']= 5
            break
          case 'price':
            v['xAxisIndex']= 6
            v['yAxisIndex']= 6
            break
        }
      })
      // // @ts-ignore
      // chart.kpiSeries[0]['markLine']=
      //   {
      //     width:2,
      //     data:
      //       [
      //         {
      //           name: 'average predecessor',
      //           type: 'average',
      //           label:{formatter: '{b}',distance:[0,0]}
      //         }
      //       ]
      //   }


      const o: any[] = chart.kpiSeries;
      const datepipe: DatePipe = new DatePipe('de-DE');

      // @ts-ignore
      this.kpiOptions = {
        title: {
          text: `${chart.kpiName}`
        },
        legend: [
          {data: ['planned quantity','actual quantity'],left: '20%'},
          {data: ['planned order intake','actual order intake'],left: '65%'},
          {data: ['planned quantity ytd','actual quantity ytd'], top: '27%',left: '20%'},
          {data: ['planned order intake ytd','actual order intake ytd'],top: '27%',left: '65%'},
          {data: ['stock'],top: '52%',left: '25%'},
          {data: ['range'],top: '52%',left: '65%'},
          {data: ['rolling avg price'],top: '74%',left: '25%'},
          {data: ['rolling avg price'],top: '74%',left: '65%'},
        ],
        tooltip:
        // {
        //   trigger: 'item',
        //   formatter: function (params: any) {
        //     let out = ''
        //     if (params.componentType === 'series') {
        //       //params.forEach( (p: { seriesName: string; value: string[]; })  => {
        //       if(
        //         (<string>params.seriesName).includes('quantity') ||
        //         (<string>params.seriesName).includes('stock') ||
        //         (<string>params.seriesName).includes('range')
        //       ){
        //         out = out + params.seriesName + ': ' + params.value[1] + '<br>'
        //           + 'Date: '+datepipe.transform(params.value[0], 'YYYY.MM.dd')
        //       } else {
        //         out = out + params.seriesName + ': ' + formatCurrency(+params.value[1], 'de', '€', 'EUR', '2.0-0') + '<br>'
        //         + 'Date: '+datepipe.transform(params.value[0], 'YYYY.MM.dd')
        //       }
        //
        //       //})
        //     } else if (params.componentType === 'markLine') {
        //       out = out + params.name
        //     }
        //     return out;
        //   },
        //   axisPointer: {
        //     animation: false
        //   }
        //
        // }
          {
            trigger: 'axis',
            formatter: function (params: any) {
              let out = params[0].axisValueLabel + '<br>';

              const usedParams: Map<string,number> = new Map<string, number>();
              // @ts-ignore
              params.forEach(param => {
                if(!usedParams.has(param.seriesName)) {
                  if (param.componentType === 'series') {

                    usedParams.set(param.seriesName,param.value[1]);
                    }


                }
              })
              const sortedMap = new Map([...usedParams.entries()].sort());
              sortedMap.forEach((value, key) => {
                if ((<string>key).includes('intake')) {
                  out = out + key + ': ' + formatCurrency(+value, 'de', '€', 'EUR', '2.0-0') + '<br>'
                } else {
                  out = out + key + ': ' + formatNumber(+value, 'de-DE') + '<br>'
                }
              })

              return out;
            }
          },

        axisPointer: {
        link: [
          {
            xAxisIndex: 'all'
          }
        ],
          show: true,
          snap: true,
          lineStyle: {
          type: 'dashed'
        }
      },

        grid: [
          { left: '7%', top: '7%', width: '38%', height: '15%' },
          { right: '7%', top: '7%', width: '38%', height: '15%' },
          { left: '7%', top: '32%', width: '38%', height: '15%' },
          { right: '7%', top: '32%', width: '38%', height: '15%' },
          { left: '7%', top: '57%', width: '38%', height: '15%' },
          { right: '7%', top: '57%', width: '38%', height: '15%' },
          { left: '7%', top: '79%', width: '38%', height: '15%' },
          { right: '7%', top: '79%', width: '38%', height: '15%' },
        ],
        dataset: {
          // Provide a set of data.
          //source: data
        },
        // Declare an x-axis (category axis).
        // The category map the first column in the dataset by default.
        xAxis: [
          { gridIndex: 0, type: 'time' },
          { gridIndex: 1, type: 'time' },
          { gridIndex: 2, type: 'time' },
          { gridIndex: 3, type: 'time' },
          { gridIndex: 4, type: 'time' },
          { gridIndex: 5, type: 'time' },
          { gridIndex: 6, type: 'time' },
          { gridIndex: 7, type: 'time' }
        ],
        // Declare a y-axis (value axis).
        yAxis:  [
          { gridIndex: 0 },
          { gridIndex: 1 },
          { gridIndex: 2 },
          { gridIndex: 3 },
          { gridIndex: 4 },
          { gridIndex: 5 },
          { gridIndex: 6 },
          { gridIndex: 7 },
        ],

        // grid: {
        //   top: 100,
        //   left: 100,
        //   right: 50,
        //   bottom: 100
        // },

        // Declare several 'bar' series,
        // every series will auto-map to each column by default.
        dataZoom: [
          {
            id: 'dataZoomX',
            type: 'slider',
            xAxisIndex: [0,1,2,3,4,5,6],
            filterMode: 'empty',
            showDataShadow: false
          }
        ],

        series: o
      };
      this.ready = true;
    }
  }

}
