import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {Subject, takeUntil} from 'rxjs';
import {MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService} from '@azure/msal-angular';
import {
  AuthenticationResult, EventMessage, EventType, InteractionStatus, PopupRequest, RedirectRequest
} from '@azure/msal-browser';
import {filter} from 'rxjs/operators';
import {NavComponent} from './components/layout/nav/nav.component';
import {NgIf} from '@angular/common';
import {Router, RouterOutlet} from '@angular/router';
import {FooterComponent} from './components/layout/footer/footer.component';
import config from 'devextreme/core/config';
import {HttpClient} from '@angular/common/http';
import {environment} from '../environments/environment';
import {AppUser, AuthorityEnum, GraphUser} from './domain/user.model';
import {AuthService} from './auth/auth.service';
import {PreviousRouteService} from './services/previous-url-service';

// import deMessages from 'devextreme/localization/messages/de.json';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [NavComponent, NgIf, RouterOutlet, FooterComponent]
})
export class AppComponent implements OnInit, OnDestroy {

  title = 'EIS.3';
  isIframe = false;
  loginDisplay = false;

  graphProfile: GraphUser | undefined;
  serverProfile: any | undefined;

  private readonly _destroying$ = new Subject<void>();

  constructor(@Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration, private authService: MsalService, private msalBroadcastService: MsalBroadcastService, private http: HttpClient, private appRoles: AuthService, private _router: Router, private previousRouteService: PreviousRouteService) {
    // @ts-ignore
    config({licenseKey: 'ewogICJmb3JtYXQiOiAxLAogICJjdXN0b21lcklkIjogImI1ZDBlZDA0LTJmMzAtMTFlNi04MGRlLTAwMjU5MGQ5ZDVmZiIsCiAgIm1heFZlcnNpb25BbGxvd2VkIjogMjQxCn0=.Pdy9WMbzpwg+4ORbtvMBS3+5r5Y3kM6+tA2ANUurBEos//7bRQThTkeAwZVocPFxOU+8FKdVif9OgqXAqeaGdTuXJBSNQAVTBZt4JbeOnv407tIaZXgrlnxsWVB8nLmQAQVtSQ=='});

    // registerLocaleData(localeDe, 'de');
  }

  ngOnInit(): void {
    this.authService.handleRedirectObservable().subscribe();
    this.isIframe = window !== window.parent && !window.opener; // Remove this line to use Angular Universal

    this.setLoginDisplay();

    this.authService.instance.enableAccountStorageEvents(); // Optional - This will enable ACCOUNT_ADDED and ACCOUNT_REMOVED events emitted when a user logs in or out of another tab or window
    this.msalBroadcastService.msalSubject$
      .pipe(filter((msg: EventMessage) => msg.eventType === EventType.ACCOUNT_ADDED || msg.eventType === EventType.ACCOUNT_REMOVED))
      .subscribe((result: EventMessage) => {
        if (this.authService.instance.getAllAccounts().length === 0) {
          window.location.pathname = '/';
        } else {
          this.setLoginDisplay();
        }
      });

    this.msalBroadcastService.inProgress$
      .pipe(filter((status: InteractionStatus) => status === InteractionStatus.None), takeUntil(this._destroying$))
      .subscribe(() => {
        this.setLoginDisplay();
        this.checkAndSetActiveAccount();
        this.setAppAuthRoles();
      });

    this.msalBroadcastService.msalSubject$
      .pipe(filter((msg: EventMessage) => msg.eventType === EventType.SSO_SILENT_SUCCESS))
      .subscribe((result: EventMessage) => {

        this.http.get(environment.apiConfig.uri).subscribe((profile) => {
          this.checkAndSetActiveAccount();
          this.setAppAuthRoles();
        });
      });
  }

  private setAppAuthRoles() {
    this.http.get(environment.apiConfig.uri).subscribe((profile) => {
      this.graphProfile = profile as GraphUser;
      const account = this.authService.instance.getAllAccounts()[0] as GraphUser;
      this.http.get(`api/users/${this.graphProfile.userPrincipalName}`).subscribe(value => {
        const appUser: AppUser = value as AppUser;

        this.appRoles.roles.next(appUser.roles.map((role) => role.name));
        const url = this.previousRouteService.getPreviousUrl();
        // todo previous url handling
          if (this.appRoles.roles.value.includes(AuthorityEnum.PURCHASE)) { //value.authorities.includes(AuthorityEnum.PURCHASE)){
            this._router.navigate(['app/purchase-products']);
          } else {
            this._router.navigate(['app/overview']);
          }
      });
    });
  }

  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }

  checkAndSetActiveAccount() {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    let activeAccount = this.authService.instance.getActiveAccount();

    if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
      let accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
    }
  }

  loginRedirect() {
    if (this.msalGuardConfig.authRequest) {
      this.authService.loginRedirect({
        ...this.msalGuardConfig.authRequest,
      } as RedirectRequest);
    } else {
      this.authService.loginRedirect();
    }
  }

  loginPopup() {
    if (this.msalGuardConfig.authRequest) {
      this.authService
        .loginPopup({...this.msalGuardConfig.authRequest} as PopupRequest)
        .subscribe((response: AuthenticationResult) => {
          this.authService.instance.setActiveAccount(response.account);
        });
    } else {
      this.authService
        .loginPopup()
        .subscribe((response: AuthenticationResult) => {
          this.authService.instance.setActiveAccount(response.account);
        });
    }
  }

  logout(popup?: boolean) {
    if (popup) {
      this.authService.logoutPopup({
        mainWindowRedirectUri: '/',
      });
    } else {
      this.authService.logoutRedirect();
    }
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}

