<h2 class="display-6 mb-5">channel store</h2>
<div class="row">
  <div class="col-12">
    <app-kpi-chart *ngIf="avm" [avm]="avm" [showNegative]="false" [perpective]="KpiPerspective.OVERVIEW" [channel]="IntakeChannel.PER"></app-kpi-chart>
  </div>
</div>
<div>For this view, a share of 25% was assumed and a specific seasonal curve was created. </div>
<pre>
+----+----------+
|year|share_pers|
+----+----------+
|2020|0.115642  |
|2021|0.126164  |
|2022|0.214882  |
|2023|0.252773  |
+----+----------+


</pre>
