import { Component } from '@angular/core';
import { EventOrtComponent } from './event-ort/event-ort.component';

@Component({
    selector: 'app-stammdaten',
    templateUrl: './stammdaten.component.html',
    styleUrls: ['./stammdaten.component.scss'],
    standalone: true,
    imports: [EventOrtComponent]
})
export class StammdatenComponent {

}
