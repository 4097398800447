<h3>Event Orte</h3>
<dx-data-grid
  id="grid"
  [dataSource]="dataSource"
  [showBorders]="true"
  [allowColumnResizing]="true"
  [allowColumnReordering]="true"
  [columnAutoWidth]="true"
>
  <dxo-sorting mode="single"></dxo-sorting>
  <dxo-selection mode="single"></dxo-selection>
  <dxo-filter-row [visible]="true"></dxo-filter-row>
  <dxo-header-filter [visible]="true"></dxo-header-filter>
  <dxo-filter-panel [visible]="true"></dxo-filter-panel>
  <dxo-search-panel
    [visible]="true"
    [width]="240"
    placeholder="Suche..."
  ></dxo-search-panel>
  <dxo-column-chooser [enabled]="true"></dxo-column-chooser>
  <dxo-state-storing [enabled]="true" storageKey="event-ort-grid" type="sessionStorage"></dxo-state-storing>

  <dxo-editing [allowAdding]="true" [allowDeleting]="true" [allowUpdating]="true"
               [useIcons]="true" mode="popup">
  </dxo-editing>

  <dxo-paging [pageSize]="8"></dxo-paging>
  <dxo-pager
    [showPageSizeSelector]="true"
    [allowedPageSizes]="[8, 10, 15]"
  ></dxo-pager>

</dx-data-grid>
