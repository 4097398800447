import {Component, OnInit, ViewChild} from '@angular/core';
import {DxDataGridComponent} from 'devextreme-angular';
import DataSource from 'devextreme/data/data_source';
import {IntakeChannel, KpiChartDTO, KpiPerspective} from '../../../domain/kpi.mpodel';
import {AdvertisingMedium} from '../../../domain/advertisingMedium';
import ArrayStore from 'devextreme/data/array_store';
import {Campaign} from '../../../domain/campaign.model';
import {AdvertisingMediumService} from '../../../services/advertising-medium.service';
import {KpiService} from '../../../services/kpi.service';
import {CampaignService} from '../../../services/campaign.service';
import {lastValueFrom} from 'rxjs';
import { NgIf } from '@angular/common';
import { KpiChartComponent } from '../../charting/kpi-chart.component';


@Component({
    selector: 'app-overview-web',
    templateUrl: './overview-web.component.html',
    styleUrls: ['./overview-web.component.scss'],
    standalone: true,
    imports: [NgIf, KpiChartComponent]
})
export class OverviewWebComponent  implements OnInit{

  public avm: AdvertisingMedium[] | undefined;
  public campaignLookup: Campaign[] = [];


  constructor(private advMService: AdvertisingMediumService) {
  }

  ngOnInit(): void {
    this.advMService.getAdvertisingMediums().subscribe(value => {
      this.avm=value.filter(adm => {
        return adm.dtype==='NEWSLETTER'
      });
    })
  }



  protected readonly KpiPerspective = KpiPerspective;
  protected readonly IntakeChannel = IntakeChannel;
}
