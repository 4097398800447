import { Component, Input, ViewChild } from '@angular/core';
import { VersionProductService } from '../../services/version-product.service';
import DataSource from 'devextreme/data/data_source';
import { lastValueFrom } from 'rxjs';
import { Catalogue } from '../../domain/catalogue.model';
import { DxDataGridComponent, DxDataGridModule } from 'devextreme-angular';
import { Version } from '../../domain/version.model';
import { ActivatedRoute } from '@angular/router';
import { Product, ProductDTO } from '../../domain/product.model';
import { ProductVersion } from '../../domain/product-version.model';
import { ProductService } from '../../services/product-service.service';
import {
  DxoFilterRowModule,
  DxoHeaderFilterModule,
  DxoFilterPanelModule,
  DxoSearchPanelModule,
  DxoSelectionModule,
  DxiColumnModule,
  DxoPagingModule,
  DxoPagerModule,
  DxoEditingModule,
} from 'devextreme-angular/ui/nested';

@Component({
  selector: 'app-version-product',
  templateUrl: './version-product.component.html',
  styleUrls: ['./version-product.component.scss'],
  standalone: true,
  imports: [
    DxDataGridModule,
    DxoFilterRowModule,
    DxoHeaderFilterModule,
    DxoFilterPanelModule,
    DxoSearchPanelModule,
    DxoSelectionModule,
    DxiColumnModule,
    DxoPagingModule,
    DxoPagerModule,
    DxoEditingModule,
  ],
})
export class VersionProductComponent {
  // @ts-ignore
  @ViewChild('versionProductGrid') versionProductGrid: DxDataGridComponent;
  // @ts-ignore
  @ViewChild('availableProducts') availableProducts: DxDataGridComponent;
  // @ts-ignore
  public dataSource: DataSource;
  // @ts-ignore
  public version: Version;
  public products: ProductDTO[] = [];
  productIdsInVersion: string[] = [];
  constructor(
    private activatedRoute: ActivatedRoute,
    private versionProductService: VersionProductService,
    private productService: ProductService,
  ) {
    this.activatedRoute.params.subscribe((params) => {
      if (params['versionId']) {
        this.dataSource = new DataSource({
          key: 'id',
          load: () => {
            return lastValueFrom(
              this.versionProductService.getVersionWithProducts(
                params['versionId'],
              ),
            )
              .then((version: Version) => {
                this.version = version;
                this.productIdsInVersion = [];
                version.productVersions.forEach((value) => {
                  this.productIdsInVersion.push(value.id.productId);
                });
                this.loadProducts();
                return {
                  data: version.productVersions,
                  totalCount: version.productVersions.length,
                };
              })
              .catch((err) => {
                throw Error(err.message);
              });
          },
          update: (key: number, _new: ProductVersion) => {
            return this.versionProductGrid.instance
              .byKey(key)
              .then((productVersion) => {
                const _productVersion: ProductVersion = { ...productVersion };
                const version: Version = _productVersion.version;
                // return lastValueFrom(this.catService.updateCatalogue(_catalogue)).then(() => {
                //   // this.catStatus = new Status('Die Änderungen wurde erfolgreich übernommen', StatusType.Success);
                // }).catch((err) => {
                //   throw Error(err.message);
                // });
              });
          },
        });
      } else {
      }
    });
  }



  loadProducts() {
    this.productService.getProductsDTO().subscribe((value) => {
      this.products = [];
      this.products = value.filter((p) => {
        return !this.productIdsInVersion.includes(p.id);
      });
    });
  }

  removeSelectionFromVersion() {
    //this.status = undefined;
    const instance = this.versionProductGrid.instance;
    const sr = instance.getRowElement(2);
    const selectedVersionProducts: ProductVersion[] =
      this.versionProductGrid.instance.getSelectedRowsData();
    if (selectedVersionProducts.length > 0) {
      const ids: string[] = selectedVersionProducts.map(
        (value) => value.id.productId,
      );
      this.versionProductService
        .removeProductsFromVersion(this.version, ids)
        .subscribe(() => {
          // @ts-ignore
          this.dataSource.reload();
          //this.status = new Status('Angebote erfolgreich entfernt', StatusType.Success);
        });
    } else {
      //this.status = new Status('Keine Angebote ausgewählt', StatusType.Info);
    }
  }

  moveSelectionToVersion() {
    //this.status = undefined;
    const selectedProducts: Product[] =
      this.availableProducts.instance.getSelectedRowsData();
    if (selectedProducts.length > 0) {
      const ids: string[] = selectedProducts.map((value) => value.id);
      this.versionProductService
        .addProductsToVersion(this.version, ids)
        .subscribe(() => {
          // @ts-ignore
          this.dataSource.reload();
          //this.status = new Status('Angebote erfolgreich hinzugefügt', StatusType.Success);
        });
    } else {
      //this.status = new Status('Keine Angebote ausgewählt', StatusType.Info);
    }
  }
}
