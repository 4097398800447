<dbc-feedback [status]="status"></dbc-feedback>
<div class="containter">
    <div class="card" style="width: 18rem;">
      <div class="card-body text-center">
        <h5 class="card-title fw-normal">Cover Bild</h5>
      </div>
      <div *ngIf="file.name === 'empty'">
        <div *ngIf="image !== ''; else keinBild">
          <img class="card-img-top px-2" src="data:image/jpeg;base64,{{image}}" alt="Cover Image gespeichert"/>
        </div>
        <ng-template #keinBild>
          <p class="text-center fst-italic">Noch kein Bild hinterlegt</p>
        </ng-template>
      </div>
      <div *ngIf="file.name !== 'empty'">
        <img class="card-img-top px-2" *ngIf="file.name !== 'empty'" [ngfSrc]="file" alt="Cover Image ausgewählt"/>
      </div>

      <div class="card-body text-center">
        <button class="btn btn-sm btn-outline-info" id="select-file" aria-describedby="Bild Auswählen" ngfSelect multiple="1"
                accept=".jpg,.png"
                *ngIf="file.name === 'empty' && image ==''" [(file)]="file">
          Bild auswählen zum Speichern
        </button>
        <button class="btn btn-sm btn-outline-info" id="select-other-file" aria-describedby="Bild austauschen Button" ngfSelect multiple="1"
                accept=".jpg,.png"
                *ngIf="file.name !== 'empty' || image !=''" [(file)]="file"
        >
          Ein anderes Bild auswählen und austauschen.
        </button>
        <button *ngIf="file.name !== 'empty'" class="btn btn-sm btn-outline-info mt-2" id="upload" aria-describedby="Bild speichern"
                (click)="startUploadCoverImage(data)" autofocus>
          Dieses Bild Speichern
        </button>
        <button *ngIf="file.name !== 'empty' || image !=''" class="btn btn-sm btn-outline-info mt-2" id="delete" aria-describedby="Bild löschen"
                (click)="deleteCoverImage(data.id)" autofocus>
          Gespeichertes Bild Löschen
        </button>
      </div>
    </div>
  </div>
