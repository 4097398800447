import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {ProductDetailService} from '../../../services/product-detail-service.service';
import {ActivatedRoute} from '@angular/router';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ProductVariant} from '../../../domain/productDetail.model';
import DataSource from 'devextreme/data/data_source';
import { DxDataGridComponent, DxDataGridModule } from 'devextreme-angular';
import { DxoSortingModule, DxoSelectionModule, DxoFilterRowModule, DxoScrollingModule, DxoPagingModule, DxiColumnModule } from 'devextreme-angular/ui/nested';

@Component({
    selector: 'app-product-parts',
    templateUrl: './product-parts.component.html',
    styleUrls: ['./product-parts.component.scss'],
    standalone: true,
    imports: [DxDataGridModule, DxoSortingModule, DxoSelectionModule, DxoFilterRowModule, DxoScrollingModule, DxoPagingModule, DxiColumnModule]
})
export class ProductPartsComponent implements OnChanges {
  @Input()selectedVariant!: ProductVariant;

  // @ts-ignore
  @ViewChild(DxDataGridComponent) partsgrid: DxDataGridComponent;

  // @ts-ignore
  productionParts:DataSource;
  constructor(private productDetailService: ProductDetailService,private activatedRoute: ActivatedRoute,public modal: NgbActiveModal,) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['selectedVariant']) this.selectedVariant = changes['selectedVariant'].currentValue;

    this.productDetailService.getProductionParts(this.selectedVariant.item).subscribe(value => {
      this.productionParts = new DataSource({
        store: value
      });
      this.partsgrid.instance.repaint()

    })
  }

  }
