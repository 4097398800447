import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import {Observable} from "rxjs";
import {Version} from "../domain/version.model";
import {AdvertisingMedium} from "../domain/advertisingMedium";

@Injectable({providedIn: 'root'})
export class AdvertisingMediumService {

  constructor(private httpClient: HttpClient) {
  }

  getAdvertisingMediums(): Observable<AdvertisingMedium[]> {
    return this.httpClient.get<AdvertisingMedium[]>(`api/advertisingMediums`);
  }
  getAdverstisingMediumVersions(key: number | undefined): Observable<Version[]> {
    return this.httpClient.get<Version[]>(`api/advertisingMediums/${key}/versions`);
  }

    getAdvertisingMediums4Campaign(selectedRowKey: any) {
      return this.httpClient.get<AdvertisingMedium[]>(`api/campaign/${selectedRowKey}/advertisingMediums`);
    }
}
