import { Component, OnInit } from '@angular/core';
import { AdvertisingMedium } from '../../../domain/advertisingMedium';
import { Campaign } from '../../../domain/campaign.model';
import { AdvertisingMediumService } from '../../../services/advertising-medium.service';
import { IntakeChannel, KpiPerspective } from '../../../domain/kpi.mpodel';
import { NgIf } from '@angular/common';
import { KpiChartComponent } from '../../charting/kpi-chart.component';

@Component({
  selector: 'app-overview-tel',
  templateUrl: './overview-tel.component.html',
  styleUrls: ['./overview-tel.component.scss'],
  standalone: true,
  imports: [NgIf, KpiChartComponent],
})
export class OverviewTelComponent implements OnInit {
  public avm: AdvertisingMedium[] | undefined;
  public campaignLookup: Campaign[] = [];

  constructor(private advMService: AdvertisingMediumService) {}

  ngOnInit(): void {
    this.advMService.getAdvertisingMediums().subscribe((value) => {
      this.avm = value;
    });
  }

  protected readonly KpiPerspective = KpiPerspective;
  protected readonly IntakeChannel = IntakeChannel;
}
