import {Component, ViewChild} from '@angular/core';
import { DxDataGridComponent, DxDataGridModule } from 'devextreme-angular';
import DataSource from 'devextreme/data/data_source';
import {KpiChartDTO, KpiPerspective} from '../../../domain/kpi.mpodel';
import {AdvertisingMedium} from '../../../domain/advertisingMedium';
import ArrayStore from 'devextreme/data/array_store';
import {Campaign} from '../../../domain/campaign.model';
import {AdvertisingMediumService} from '../../../services/advertising-medium.service';
import {KpiService} from '../../../services/kpi.service';
import {CampaignService} from '../../../services/campaign.service';
import {lastValueFrom} from 'rxjs';
import {Workbook} from 'exceljs';
import {exportDataGrid} from 'devextreme/excel_exporter';
import {saveAs} from 'file-saver';
import {ContentReadyEvent} from 'devextreme/ui/data_grid';
import { NgIf } from '@angular/common';
import { KpiChartComponent } from '../../charting/kpi-chart.component';
import { DxoSelectionModule, DxoHeaderFilterModule, DxoFilterRowModule, DxoFilterPanelModule, DxiColumnModule, DxoLookupModule, DxoMasterDetailModule, DxoPagingModule, DxoPagerModule, DxoExportModule } from 'devextreme-angular/ui/nested';
import { DxTemplateModule } from 'devextreme-angular/core';
import { VersionDetailComponent } from '../../version-detail/version-detail.component';

@Component({
    selector: 'app-order-channel',
    templateUrl: './order-channel.component.html',
    styleUrls: ['./order-channel.component.scss'],
    standalone: true,
    imports: [NgIf, KpiChartComponent, DxDataGridModule, DxoSelectionModule, DxoHeaderFilterModule, DxoFilterRowModule, DxoFilterPanelModule, DxiColumnModule, DxoLookupModule, DxoMasterDetailModule, DxTemplateModule, VersionDetailComponent, DxoPagingModule, DxoPagerModule, DxoExportModule]
})
export class OrderChannelComponent {
  // @ts-ignore
  @ViewChild(DxDataGridComponent) advGrid: DxDataGridComponent;
  public dataSource: DataSource;
  public selected: KpiChartDTO | undefined;
  public avm: AdvertisingMedium[] | undefined;
  private shadowStore: ArrayStore | undefined;
  public campaignLookup: Campaign[] = [];


  constructor(private advMService: AdvertisingMediumService, private kpiService: KpiService, private campService: CampaignService) {
    this.dataSource = new DataSource({
      key: 'id',
      load: () => {
        return lastValueFrom(this.advMService.getAdvertisingMediums()).then((advM: AdvertisingMedium[]) =>{
          this.avm=advM;
          this.campService.getCampaigns().subscribe(value => {
            this.campaignLookup = value;
          })
          this.shadowStore = new ArrayStore<any, any>({key:'id', data: advM});
          return {
            data: advM,
            totalCount: advM.length
          };
        }).catch((err) => {
          throw Error(err.message);
        });
      }
    })
  }

  ngOnInit(): void {
  }

  selectionChanged(e: any) {
    if(e.selectedRowKeys.length===1){
      this.kpiService.getKpiBarChatCompare(e.selectedRowKeys[0]).subscribe((value: KpiChartDTO)=>{
        this.selected=value;
      })
    }
  }
  onExporting(e: any) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Daten');

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Overview.xlsx');
      });
    });
  }

  contentReady(eventInfo: ContentReadyEvent) {
    const grid  = eventInfo.component;
    let filterExpr = grid?.getCombinedFilter(true);
    const dataSource = grid?.getDataSource();
    const loadOptions = dataSource?.loadOptions();
    if(this.shadowStore) {
      this.shadowStore
        .load({filter: filterExpr, sort: loadOptions?.sort, group: loadOptions?.group})
        .then((result: any) => {
          this.avm = result;
        });
    }
  }


  protected readonly KpiPerspective = KpiPerspective;
}
