import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {SafeUrl} from "@angular/platform-browser";
import {AdvertisingMedium} from "../../domain/advertisingMedium";
import {CoverImageService} from "../../services/cover-image.service";
import { Status, StatusType, FeedbackComponent } from "../interaction/feedback/feedback.component";
import {lastValueFrom} from "rxjs";
import { NgIf } from '@angular/common';
import { ngfModule } from 'angular-file';

@Component({
    selector: 'app-cover-image',
    templateUrl: './cover-image.component.html',
    styleUrls: ['./cover-image.component.scss'],
    standalone: true,
    imports: [FeedbackComponent, NgIf, ngfModule]
})
export class CoverImageComponent implements OnChanges{
  @Input() data: any;
  public file: File = new File([],"empty");
  public image: SafeUrl = "";
  //@ts-ignore
  public status: Status = undefined;


  constructor(private coverImageService: CoverImageService) {
  }

  ngOnChanges(changes: SimpleChanges): void {

    const adm: AdvertisingMedium = changes['data'].currentValue;
    this.loadCoverImage(adm);
  }

  public startUploadCoverImage(advertisingMedium: AdvertisingMedium): void {
    this.coverImageService.uploadImage(advertisingMedium, this.file).subscribe(value => {
      //this.dataSource?.reload();
      this.file = new File([],"empty");
      this.loadCoverImage(advertisingMedium);
      this.status = new Status('Das Bild wurde erfolgreich gespeichert', StatusType.Success);
    })
  }

  public loadCoverImage(advertisingMedium: AdvertisingMedium): any {
    this.coverImageService.getImage(advertisingMedium).subscribe(value => {
      if(value)
      {
        this.image = value;
      }
    })
  }

  public deleteCoverImage(key: number): any {
    return lastValueFrom(this.coverImageService.deleteImage(key)).then(() => {
      this.image = "";
      this.file = new File([],"empty");
      this.status = new Status('Das Bild wurde erfolgreich gelöscht', StatusType.Success);
    }).catch((err) => {
      throw Error(err.error.message);
    })

  }
}
