import {Component, ViewChild} from '@angular/core';
import { DxDataGridComponent, DxDataGridModule } from 'devextreme-angular';
import DataSource from 'devextreme/data/data_source';
import {lastValueFrom} from 'rxjs';
import {CampaignService} from '../../services/campaign.service';
import {Campaign} from '../../domain/campaign.model';
import {KpiChartDTO} from '../../domain/kpi.mpodel';
import {AdvertisingMediumService} from '../../services/advertising-medium.service';
import {AdvertisingMedium} from '../../domain/advertisingMedium';
import { Status, StatusType, FeedbackComponent } from "../interaction/feedback/feedback.component";
import { DxoSelectionModule, DxoFilterRowModule, DxoHeaderFilterModule, DxoFilterPanelModule, DxoSearchPanelModule, DxoColumnChooserModule, DxoStateStoringModule, DxoEditingModule, DxiColumnModule, DxiValidationRuleModule, DxoPagingModule, DxoPagerModule } from 'devextreme-angular/ui/nested';
import { AdvertisingMedium4CampaignComponent } from './advertising-medium4-campaign/advertising-medium4-campaign.component';

@Component({
    selector: 'app-campaign',
    templateUrl: './campaign.component.html',
    styleUrls: ['./campaign.component.scss'],
    standalone: true,
    imports: [FeedbackComponent, DxDataGridModule, DxoSelectionModule, DxoFilterRowModule, DxoHeaderFilterModule, DxoFilterPanelModule, DxoSearchPanelModule, DxoColumnChooserModule, DxoStateStoringModule, DxoEditingModule, DxiColumnModule, DxiValidationRuleModule, DxoPagingModule, DxoPagerModule, AdvertisingMedium4CampaignComponent]
})
export class CampaignComponent {
  // @ts-ignore
  @ViewChild(DxDataGridComponent) campaignGrid: DxDataGridComponent;
  public dataSource: DataSource;
  private isNewRow: boolean=false;
  public advertisingMediums: AdvertisingMedium[] = [];
  // @ts-ignore
  public status: Status = undefined;
  constructor(private campaignService: CampaignService, private admService: AdvertisingMediumService) {
    this.dataSource = new DataSource({
      key: 'id',
      load: () => {
        return lastValueFrom(this.campaignService.getCampaigns()).then((campaigns: Campaign[]) => {
          return {
            data: campaigns,
            totalCount: campaigns.length
          };
        }).catch((err) => {
          throw Error(err.message);
        });
      },
      insert: (campaign: Campaign) => {
        const _campaign = { ...campaign };
        // @ts-ignore
        delete _campaign['__KEY__'];
        return lastValueFrom(this.campaignService.createCampaign(_campaign)).then((campaign: Campaign) => {
          this.status = new Status('Die Kampagne wurde erfolgreich angelegt', StatusType.Success);
          return campaign;
        }).catch((err) => {
          throw err;
        });
      },
      update: (key: number, _new: Campaign) => {
        return this.campaignGrid.instance.byKey(key).then((catalogue) => {
          const _campaign = { ...catalogue };
          delete _campaign['__KEY__'];
          return lastValueFrom(this.campaignService.updateCampaign(_campaign)).then(() => {
            this.status = new Status('Die Änderungen wurde erfolgreich übernommen', StatusType.Success);
          }).catch((err) => {
            throw Error(err.message);
          });
        });
      },
      remove: (key: number ) => {
        return lastValueFrom(this.campaignService.deleteCampaign(key)).then(() => {
          this.status = new Status('Die Kampagne wurde erfolgreich gelöscht', StatusType.Success);
        }).catch((err) => {
          throw Error( err.error.message);
        });
      }
    });
  }

  selectionChanged(e: any) {
    if(e.selectedRowKeys.length===1){
      this.admService.getAdvertisingMediums4Campaign(e.selectedRowKeys[0]).subscribe((value: AdvertisingMedium[])=>{
        this.advertisingMediums=value;
      })
    }
  }
}
