<div class="modal-header">
  <h4 class="modal-title">Details: {{product.productName}}</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
</div>

<div class="modal-body">
  <div class="row">
    <div class="col-8">
      <div class="card">
        <div class="card-body">
          <h6>Variants</h6>
            <dx-data-grid
              id="variantgrid"
              [dataSource]="variants"
              [keyExpr]="'item'"
              [showBorders]="true"
              [allowColumnResizing]="true"
              [allowColumnReordering]="true"
              [columnAutoWidth]="true"
              (onSelectionChanged)="selectionChanged($event)"
            >
              <dxo-sorting mode="multiple"></dxo-sorting>
              <dxo-selection mode="single"></dxo-selection>
              <dxo-filter-row [visible]="true"></dxo-filter-row>

              <dxo-scrolling columnRenderingMode="virtual" mode="infinite"></dxo-scrolling>
              <dxo-paging [enabled]="false"></dxo-paging>
              <dxi-column
                dataField="itemHead"
              >
              </dxi-column>
              <dxi-column
                dataField="item"
                dataType="string"
                [sortIndex]="2"
                sortOrder="asc"
              >
              </dxi-column>
              <dxi-column
                dataField="description"
                dataType="string"
              >
              </dxi-column>
              <dxi-column
                dataField="supplier"
                dataType="string">
              </dxi-column>
              <dxi-column
                dataField="priorityItem"
                dataType="boolean"
                [sortIndex]="1"
                sortOrder="desc"
              >
              </dxi-column>


              <dxi-column

                dataField="amountActual"
                dataType="number"
              >
              </dxi-column>
              <dxi-column
                dataField="amountInStock"
                dataType="number"
              >
              </dxi-column>
              <dxi-column
                name="stockMinusNewOrders"
                caption="Stock Minus New Orders"
                [calculateCellValue]="stockMinusNewOrders">
              </dxi-column>

              <dxi-column
                dataField="amountInTransit"
                dataType="number"
              >
              </dxi-column>
              <dxi-column
                dataField="amountOrdered"
                dataType="number"
              >
              </dxi-column>
              <dxi-column
                dataField="costPrice"
                dataType="number"
                [format]="{ style: 'currency', currency: 'EUR' }"
              >
              </dxi-column>

              <dxi-column
                caption="Link zu Nav."
                [fixed]="true"
                fixedPosition="right"
                cellTemplate="linkTemplate">
                <div *dxTemplate="let d of 'linkTemplate'">
                  <a  target="_blank" href="http://gardeasvnav01.garpa.com:8080/GARPA_LIVE1/?company=Garpa_DE&page=31&filter='No.' IS '{{d.data.item}}'"> nav </a>
                  <!--              <button (click)="routeToNav(d.data)" class="dx-button-link">-->
                  <!--                Nav-->
                  <!--              </button>-->
                </div>
              </dxi-column>

              <dxo-summary>
                <dxi-total-item column="OrderNumber" summaryType="count"> </dxi-total-item>

                <dxi-total-item
                  column="amountActual"
                  summaryType="sum"
                >
                </dxi-total-item>
                <dxi-total-item
                  column="amountInStock"
                  summaryType="sum"
                >
                </dxi-total-item>
                <dxi-total-item
                  column="stockMinusNewOrders"
                  summaryType="sum"
                >
                </dxi-total-item>
                <dxi-total-item
                  column="amountInTransit"
                  summaryType="sum"
                >
                </dxi-total-item>
                <dxi-total-item
                  column="amountOrdered"
                  summaryType="sum"
                >
                </dxi-total-item>

              </dxo-summary>

            </dx-data-grid>
        </div>
      </div>
    </div>
  </div>
</div>

  <ul ngbNav #nav="ngbNav"  class="nav-tabs">



    <li [ngbNavItem]="1">
      <button ngbNavLink>Parts</button>
      <ng-template ngbNavContent>
        <app-product-parts [selectedVariant]="selectedVariant"/>

      </ng-template>
    </li>

    <li [ngbNavItem]="2">
      <button ngbNavLink>Transfers</button>
      <ng-template ngbNavContent>
        <app-transfer-lines [selectedVariant]="selectedVariant"></app-transfer-lines>

      </ng-template>
    </li>

    <li [ngbNavItem]="3">
      <button ngbNavLink>Purchase Lines</button>
      <ng-template ngbNavContent>

        <app-purchase-lines [selectedVariant]="selectedVariant"></app-purchase-lines>

      </ng-template>
    </li>

    <li [ngbNavItem]="4">
      <button ngbNavLink>Assembly</button>
      <ng-template ngbNavContent>
        <app-assembly-product [selectedVariant]="selectedVariant"></app-assembly-product>

      </ng-template>
    </li>
  </ul>

    <div [ngbNavOutlet]="nav" class="px-3 mt-2"></div>






