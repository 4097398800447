<ng-container *ngIf="hasAdvM">
  <dx-data-grid
    [allowColumnResizing]="true"
    [dataSource]="data"
    [showBorders]="true"
    [columnMinWidth]="50"
    id="admGrid"
    keyExpr="id"
    [allowColumnReordering]="true"
    [columnAutoWidth]="true"
  >
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-header-filter [visible]="true"></dxo-header-filter>
    <dxo-filter-panel [visible]="true"></dxo-filter-panel>
    <dxo-search-panel
      [visible]="true"
      [width]="240"
      placeholder="Suche..."
    ></dxo-search-panel>
    <dxi-column
      caption="name"
      dataField="name"
      dataType="string"
    ></dxi-column>

    <dxi-column
      dataField="start"
      caption="Start"
      dataType="date" [format]="'dd.MM.yyyy'"
    >
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>

    <dxi-column
      dataField="end"
      caption="Ende"
      dataType="date"
      [format]="'dd.MM.yyyy'"
    >        <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>
    <dxo-paging [pageSize]="8"></dxo-paging>
    <dxo-pager
      [showPageSizeSelector]="true"
      [allowedPageSizes]="[8, 10, 15]"
      [showNavigationButtons]="true"
    ></dxo-pager>
  </dx-data-grid>
</ng-container>

