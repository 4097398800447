import {Component, Input, OnChanges, SimpleChanges, ViewChild} from '@angular/core';
import {ProductVariant} from '../../../domain/productDetail.model';
import { DxDataGridComponent, DxDataGridModule } from 'devextreme-angular';
import DataSource from 'devextreme/data/data_source';
import {ProductDetailService} from '../../../services/product-detail-service.service';
import {ActivatedRoute} from '@angular/router';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { DxoSortingModule, DxoSelectionModule, DxoFilterRowModule, DxoScrollingModule, DxoPagingModule, DxiColumnModule } from 'devextreme-angular/ui/nested';

@Component({
    selector: 'app-purchase-lines',
    templateUrl: './purchase-lines.component.html',
    styleUrls: ['./purchase-lines.component.scss'],
    standalone: true,
    imports: [DxDataGridModule, DxoSortingModule, DxoSelectionModule, DxoFilterRowModule, DxoScrollingModule, DxoPagingModule, DxiColumnModule]
})
export class PurchaseLinesComponent  implements OnChanges {
  @Input()selectedVariant!: ProductVariant;

  // @ts-ignore
  @ViewChild(DxDataGridComponent) purchasegrid: DxDataGridComponent;

  // @ts-ignore
  ds:DataSource;
  constructor(private productDetailService: ProductDetailService,private activatedRoute: ActivatedRoute,public modal: NgbActiveModal,) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['selectedVariant']) this.selectedVariant = changes['selectedVariant'].currentValue;

    this.productDetailService.getPurchaseLine(this.selectedVariant.item).subscribe(value => {
      this.ds = new DataSource({
        store: value
      });
      this.purchasegrid.instance.repaint()

    })
  }

}
