import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import {Observable} from "rxjs";
import {Mailing} from "../domain/mailing.model";
import {Version} from "../domain/version.model";

@Injectable({providedIn: 'root'})
export class MailingService {

  constructor(private httpClient: HttpClient) {

  }

  getMailings(): Observable<Mailing[]> {
    return this.httpClient.get<Mailing[]>(`api/mailings`);
  }
  createMailing(mailing: Mailing): Observable<Mailing> {
    return this.httpClient.post<Mailing>(`api/mailings`, mailing);
  }
  updateMailing(mailing: Mailing): Observable<Mailing> {
    return this.httpClient.put<Mailing>(`api/mailings`, mailing);
  }
  deleteMailing(key: number): Observable<any> {
    return this.httpClient.delete<any>(`api/mailings/${key}`);
  }
  getMailingVersions(key: number | undefined): Observable<Version[]> {
    return this.httpClient.get<Version[]>(`api/advertisingMedium/${key}/versions`);
  }
}
