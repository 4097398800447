import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import {Observable} from "rxjs";
import {AdvertisingMedium} from "../domain/advertisingMedium";

@Injectable({providedIn: 'root'})
export class CoverImageService {

  constructor(private httpClient: HttpClient) {
  }

  uploadImage(advertisingMedium: AdvertisingMedium, file: File): Observable<any> {
    const myFormData: FormData = new FormData();
    myFormData.append('file', file, file.name);
    return this.httpClient.post(`api/advertisingMediums/${advertisingMedium.id}/images/upload-image`, myFormData, {reportProgress: true});
  }

  getImage(advertisingMedium: AdvertisingMedium): Observable<any> {
    return this.httpClient.get<any>(`api/advertisingMediums/${advertisingMedium.id}/images/get-image`);
  }

  deleteImage(key: number): Observable<any> {
    return this.httpClient.delete(`api/advertisingMediums/${key}/images/delete-image`);
  }


}
