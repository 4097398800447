<div class="modal-header">
  <h5>Details zu {{ adm?.name }}</h5>
</div>
<div class="modal-body">
<div class="row">
  <div class="col-12">
    <div class="row">
      <div class="col-6">
        <am-bar-chart-compare *ngIf="barChart" [kpiChartDTO]="barChart" ></am-bar-chart-compare>
      </div>
      <div class="col-6">
        <am-line-chart-compare *ngIf="lineChart" [kpiLineChartDTO]="lineChart"></am-line-chart-compare>
      </div>
    </div>
  </div>
</div>
</div>
