import {Component, Input, OnInit} from '@angular/core';
import {KpiChartDTO, KpiLineChartDTO} from '../../../domain/kpi.mpodel';
import {AdvertisingMediumService} from '../../../services/advertising-medium.service';
import {KpiService} from '../../../services/kpi.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Newsletter} from '../../../domain/newsletter.model';
import {AdvertisingMedium} from '../../../domain/advertisingMedium';
import { NgIf } from '@angular/common';
import { AmBarChartCompareComponent } from '../../charting/am-bar-chart-compare/am-bar-chart-compare.component';
import { AmLineChartCompareComponent } from '../../charting/am-line-chart-compare/am-line-chart-compare.component';
// import {UserService} from '../../../auth/user.service';
// import {UserActions} from '../../../auth/user.actions';
// import {Store} from '@ngrx/store';
// import {User} from '../../../auth/app.state';

@Component({
    selector: 'app-overview-detail',
    templateUrl: './overview-detail.component.html',
    styleUrls: ['./overview-detail.component.scss'],
    standalone: true,
    imports: [NgIf, AmBarChartCompareComponent, AmLineChartCompareComponent]
})
export class OverviewDetailComponent implements OnInit {
  public barChart: KpiChartDTO | undefined;
  public lineChart: KpiLineChartDTO | undefined;
  adm!: AdvertisingMedium ;

  constructor(private activeModal: NgbActiveModal,private advMService: AdvertisingMediumService,
              // private store: Store<{ user: User }>,
              // private userService: UserService,
              private kpiService: KpiService){


  }

  ngOnInit(): void {
        if (this.adm?.id) {
          this.kpiService.getKpiBarChatCompare(this.adm.id).subscribe((value: KpiChartDTO) => {
            this.barChart = value;
            this.kpiService.getKpiLineChatCompare(this.adm.id).subscribe((value: KpiLineChartDTO) => {
              this.lineChart = value;
            })
          })
        }
    }
}
