<dx-data-grid
  id="assemblygrid"
  [dataSource]="ds"
  [showBorders]="true"
  [allowColumnResizing]="true"
  [allowColumnReordering]="true"
  [columnAutoWidth]="true"
>
  <dxo-sorting mode="multiple"></dxo-sorting>
  <dxo-selection mode="single"></dxo-selection>
  <dxo-filter-row [visible]="true"></dxo-filter-row>

  <dxo-scrolling columnRenderingMode="virtual" mode="infinite"></dxo-scrolling>
  <dxo-paging [enabled]="false"></dxo-paging>
  <dxi-column
    caption="item"
    dataField="assemblyPartId.assemblyItem"
    dataType="string"

  >
  </dxi-column>
  <dxi-column
    caption="description"
    dataField="assembyItemDescription"
    dataType="string"
  ></dxi-column>
  <dxi-column
    caption="description"
    dataField="amountUsed"
    dataType="string"
  ></dxi-column>

</dx-data-grid>
