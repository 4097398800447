<div class="d-flex flex-column min-vh-100">
  <header>
    <app-nav></app-nav>
  </header>
  <main role="main" id="main" class="container-fluid flex-fill pt-3 px-4">
<!--    <a class="title" href="/">{{ title }}</a>-->





<!--      <button (click)="loginRedirect()">Login using Redirect</button>-->




<!--      <button  (click)="logout()">Logout using Redirect</button>-->


<!--    <hr>-->

    <!--This is to avoid reload during acquireTokenSilent() because of hidden iframe -->
    <router-outlet *ngIf="!isIframe"></router-outlet>
  </main>
  <app-footer></app-footer>
</div>
