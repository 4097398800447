import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs';
import {Event} from '../domain/event.model';
// import {EisUser} from '../auth/eis-user.model';
import {EisUserGridStateDTO, GridState} from '../domain/grid-state.model';
import {EisUser} from '../auth.bak/eis-user.model';
import {User} from '../auth.bak/app.state';
// import {User} from '../auth/app.state';

@Injectable({
  providedIn: 'root'
})
export class EisUserService {

  constructor(private httpClient: HttpClient) {
  }

  getUserByLogin(login: string): Observable<EisUser> {
    return this.httpClient.get<EisUser>(`api/users/${login}`)
  }

  getUsersGridState(): Observable<EisUserGridStateDTO[]> {
    return this.httpClient.get<EisUserGridStateDTO[]>(`api/users/users-grid-state`)
  }

  getUserGridState(email: string): Observable<EisUserGridStateDTO[]> {
    return this.httpClient.get<EisUserGridStateDTO[]>(`api/users/${email}/user-grid-state`)
  }

  setUserGridState(dto: EisUserGridStateDTO): Observable<any> {
    return this.httpClient.put(`api/users/set-user-grid-state`,dto)
  }

  findUsersForPurchase(): Observable<User[]> {
    return this.httpClient.get<User[]>(`api/users/find-users-for-purchase`)
  }


  createUserGridState(_state:  EisUserGridStateDTO): Observable<EisUserGridStateDTO>  {
    return this.httpClient.post<EisUserGridStateDTO>(`api/users/set-user-grid-state`,_state)
  }

  deleteUserGridState(key: any): Observable<any> {
    return this.httpClient.delete<any>(`api/users/user-grid-state/${key['uid']}/${key['statename']}`);
  }
}
