import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgIf } from '@angular/common';

@Component({
    selector: 'dbc-confirm-modal',
    templateUrl: './confirm-modal.component.html',
    standalone: true,
    imports: [NgIf]
})
export class ConfirmModalComponent {

  title: string | undefined;
  text: string | undefined;
  cancelButton = 'Abbrechen';
  confirmButton = 'Ja';
  confirmOnly = false;

  constructor(public activeModal: NgbActiveModal) {
  }

  cancel() {
    this.activeModal.dismiss();
  }

  confirm() {
    this.activeModal.close();
  }
}
