import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs';
import {ActiveProduct, DiscontinuedProduct, NewProduct, Product, ProductDTO} from '../domain/product.model';
import {DiscontinuedProductComponent} from '../components/discontinued-product/discontinued-product.component';
import {Version} from '../domain/version.model';

@Injectable({providedIn: 'root'})
export class ProductService {

  constructor(private httpClient: HttpClient) {
  }
  getProducts(): Observable<Product[]>  {
    return this.httpClient.get<Product[]>(`api/products`);
  }
  getProductsDTO(): Observable<ProductDTO[]>  {
    return this.httpClient.get<ProductDTO[]>(`api/product-dtos`);
  }

  getDiscontinuedProduct(id: string): Observable<DiscontinuedProduct>  {
    return this.httpClient.get<DiscontinuedProduct>(`api/discontinued-products/${id}`);
  }
  saveDiscontinuedProduct(_dp: any):Observable<DiscontinuedProduct> {
    return this.httpClient.put<DiscontinuedProduct>(`api/discontinued-products`, _dp);
  }

  createDiscontinuedProduct(_dp: any):Observable<DiscontinuedProduct> {
    return this.httpClient.post<DiscontinuedProduct>(`api/discontinued-products`, _dp);
  }

  removeDiscontinuedProduct(_dp: any): Observable<any> {
    return this.httpClient.delete<any>(`api/discontinued-products/${_dp.id}`);
  }




  deleteNewProduct(key: string): Observable<any> {
    return this.httpClient.delete<any>(`api/new-products/${key}`);
  }

  updateNewProducts(_product: any): Observable<NewProduct> {
    return this.httpClient.put<NewProduct>(`api/new-products`, _product);
  }

  getNewProducts(): Observable<NewProduct[]> {
    return this.httpClient.get<NewProduct[]>(`api/new-products`);
  }

  createNewProducts(_product: NewProduct):Observable<NewProduct> {
    return this.httpClient.post<NewProduct>(`api/new-products`, _product);
  }

  getActiveProducts(): Observable<ActiveProduct[]> {
    return this.httpClient.get<ActiveProduct[]>(`api/active-products`);
  }


}
