<dbc-feedback [status]="status"></dbc-feedback>
<div class="row">
    <div class="col-8">
        <h1 class="display-6">Campaigns</h1>
<dx-data-grid
              [allowColumnResizing]="true"
              [dataSource]="dataSource"
              [showBorders]="true"
              [columnMinWidth]="50"
              id="campaignGrid"

              [allowColumnReordering]="true"
              [columnAutoWidth]="true"
              (onSelectionChanged)="selectionChanged($event)"
>
    <dxo-selection mode="single"></dxo-selection>
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-header-filter [visible]="true"></dxo-header-filter>
    <dxo-filter-panel [visible]="true"></dxo-filter-panel>
    <dxo-search-panel
            [visible]="true"
            [width]="240"
            placeholder="Suche..."
    ></dxo-search-panel>
    <dxo-column-chooser [enabled]="true"></dxo-column-chooser>
    <dxo-state-storing [enabled]="true" storageKey="campaign-grid" type="sessionStorage"></dxo-state-storing>

    <dxo-editing [allowAdding]="true" [allowDeleting]="true" [allowUpdating]="true"
                 [useIcons]="true" mode="popup"></dxo-editing>


    <dxi-column
            caption="name"
            dataField="name"
            dataType="string"
    ></dxi-column>
    <dxi-column
            caption="description"
            dataField="description"
            dataType="string"
            [visible]="false"
    ></dxi-column>



    <dxi-column
            dataField="start"
            caption="Start"
            dataType="date" [format]="'dd.MM.yyyy'"
    >
        <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>

    <dxi-column
            dataField="end"
            caption="Ende"
            dataType="date"
            [format]="'dd.MM.yyyy'"
    >        <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>

    <dxo-paging [pageSize]="8"></dxo-paging>
    <dxo-pager
            [showPageSizeSelector]="true"
            [allowedPageSizes]="[8, 10, 15]"
            [showNavigationButtons]="true"
    ></dxo-pager>

</dx-data-grid>
</div>
<div class="col-4">
    <h1 class="display-6">Zugehörige Werbeträger</h1>
<advertising-medium4-campaign [adms]="advertisingMediums"></advertising-medium4-campaign>
</div>
</div>
