import {Component, OnInit, ViewChild} from '@angular/core';
import {AdvertisingMediumService} from '../../../services/advertising-medium.service';


import {AdvertisingMedium} from '../../../domain/advertisingMedium';
import {TaskM} from '../../../domain/task.model';
import {TaskDblClickEvent} from 'devextreme/ui/gantt';
import { DxGanttComponent, DxGanttModule } from 'devextreme-angular';
import {AdvertisingMediumStatus} from '../../../domain/enum.model';
import { DxoTasksModule, DxiColumnModule, DxoToolbarModule, DxiItemModule, DxoFilterRowModule, DxoHeaderFilterModule, DxoEditingModule } from 'devextreme-angular/ui/nested';

@Component({
    selector: 'app-gantt',
    templateUrl: './gantt.component.html',
    styleUrls: ['./gantt.component.scss'],
    standalone: true,
    imports: [DxGanttModule, DxoTasksModule, DxiColumnModule, DxoToolbarModule, DxiItemModule, DxoFilterRowModule, DxoHeaderFilterModule, DxoEditingModule]
})
export class GanttComponent implements OnInit{
  public tasks: TaskM[]=[];
  public  adms: AdvertisingMedium[]=[];

    // @ts-ignore
    @ViewChild(DxGanttComponent, { static: false }) gantt: DxGanttComponent;
  constructor(private advMService: AdvertisingMediumService) {
    this.advMService.getAdvertisingMediums().subscribe(value => {
      this.adms =value.filter(value1 => {
        const d = new Date(value1.start)
        return d.getFullYear()>=2024;
      })

      // this.tasks.push({id:1, parentId:-1,end:new Date(2025,0,1), title:'Marketing', start:new Date(2022,0,1), progress:0})
      // this.tasks.push({id:2, parentId:1,end:new Date(2025,0,1), title:'Catalugue', start:new Date(2022,0,1), progress:0})
      const tass:TaskM[]=[{
        id: 1,
        parentId: -1,
        title: 'Marketing',
        start: undefined,
        end: undefined,
        progress: 0,
      },
        {
          id: 2,
          parentId: 1,
          title: 'Ferien',
          start: undefined,
          end: undefined,
          progress: 0,
        },
        {
          id: 3,
          parentId: 1,
          title: 'Feiertage',
          start: undefined,
          end: undefined,
          progress: 0,
        },
        {
        id: 10,
        parentId: 1,
        title: 'Catalogue',
        start: undefined,
        end: undefined,
        progress: 0,
      }, {
        id: 20,
        parentId: 1,
        title: 'Event',
        start: undefined,
        end: undefined,
        progress: 0,
      },
        {
          id: 30,
          parentId: 1,
          title: 'Newsletter',
          start: undefined,
          end: undefined,
          progress: 0,
        }
      ];
      tass.push({
        id: 10111,
        parentId: 3,
        title: 'Sylvester',
        start: new Date('2023-01-01'),
        end: new Date('2023-01-01'),
        progress: 0,
      })
      tass.push({
        id: 10112,
        parentId: 3,
        title: 'Sylvester',
        start: new Date('2024-01-01'),
        end: new Date('2024-01-01'),
        progress: 0,
      })

      tass.push({
        id: 10011,
        parentId: 2,
        title: 'Weihnachtsferien',
        start: new Date('2023-12-23'),
        end: new Date('2024-01-08'),
        progress: 0,
      })
      tass.push({
        id: 10012,
        parentId: 2,
        title: 'Weihnachtsferien',
        start: new Date('2022-12-23'),
        end: new Date('2023-01-08'),
        progress: 0,
      })
      tass.push({
        id: 10013,
        parentId: 2,
        title: 'Weihnachtsferien',
        start: new Date('2024-12-23'),
        end: new Date('2025-01-08'),
        progress: 0,
      })
      this.adms.forEach(am => {
        if(am.dtype==='EVENT'){
          tass.push({
            id: am.id+100,
            parentId: 20,
            title: am.name,
            start: am.start,
            end: am.end,
            progress: 0,
          })
        }
        if(am.dtype==='NEWSLETTER'){
          tass.push({
            id: am.id+100,
            parentId: 30,
            title: am.name,
            start: am.start,
            end: am.end,
            progress: 0,
          })
        }
        if(am.dtype==='CATALOGUE'){
          tass.push({
            id: am.id+100,
            parentId: 10,
            title: am.name,
            start: am.start,
            end: am.end,
            progress: 0,
          })
        }
      })
      this.tasks=tass;
      setTimeout(() =>
          {
            this.gantt.instance.collapseTask(2)
            this.gantt.instance.collapseTask(3)
           // this.gantt.instance.expandTask(1)
          },
          100);
    })
  }

  ngOnInit(): void {



  }

  taskDblClick(e: TaskDblClickEvent) {
      const a =e;
      // e.data.title= e.data.title + ' 1'
      // this.tasks.forEach(value => {
      //   if(value.id===e.key){
      //     value.title='12';
      //   }
      // })
     // g.instance.updateTask(key, data)
      const g = this.gantt;
      // const ts = g.tasks;
      // // @ts-ignore
      // g.tasks.dataSource.forEach(value => {
      //     if(value.id===e.key){
      //         value.title='12';
      //     }
      // })
      //g.instance.repaint();
     // e.cancel=true;
  }
}
