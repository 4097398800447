import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {KpiLineChartDTO} from '../../../domain/kpi.mpodel';
import {KpiService} from '../../../services/kpi.service';
import {AdvertisingMedium} from '../../../domain/advertisingMedium';
import { NgIf } from '@angular/common';
import { DxDataGridModule } from 'devextreme-angular';
import { DxoFilterRowModule, DxoHeaderFilterModule, DxoFilterPanelModule, DxoSearchPanelModule, DxiColumnModule, DxiValidationRuleModule, DxoPagingModule, DxoPagerModule } from 'devextreme-angular/ui/nested';

@Component({
    selector: 'advertising-medium4-campaign',
    templateUrl: './advertising-medium4-campaign.component.html',
    styleUrls: ['./advertising-medium4-campaign.component.scss'],
    standalone: true,
    imports: [NgIf, DxDataGridModule, DxoFilterRowModule, DxoHeaderFilterModule, DxoFilterPanelModule, DxoSearchPanelModule, DxiColumnModule, DxiValidationRuleModule, DxoPagingModule, DxoPagerModule]
})
export class AdvertisingMedium4CampaignComponent implements OnChanges{
  @Input() adms: AdvertisingMedium[] | undefined;

  data:AdvertisingMedium[]=[]
  hasAdvM: boolean = false;
  constructor() {
  }

  ngOnChanges(changes: SimpleChanges): void {

    const adms: AdvertisingMedium[] = changes['adms'].currentValue;
    if(adms=== undefined){
      this.data = []
    } else {
      this.hasAdvM = adms.length > 0;
      this.data = adms
    }
  }

}
