<h2 class="display-6 mb-5">order channel</h2>
<div class="row">
  <div class="col-12">
    <app-kpi-chart *ngIf="avm"  [avm]="avm" [showNegative]="true" [perpective]="KpiPerspective.ORDER_CHANNEL"></app-kpi-chart>
  </div>
</div>
<div class="row">
  <dx-data-grid
    id="advGrid"
    [dataSource]="dataSource"
    [showBorders]="true"
    [allowColumnResizing]="true"
    [columnAutoWidth]="true"
    (onSelectionChanged)="selectionChanged($event)"
    (onExporting)="onExporting($event)"
    (onContentReady)="contentReady($event)"
    [filterValue]="['start','anyof',[2024]]"
  >
    <dxo-selection mode="single" showCheckBoxesMode="always"></dxo-selection>
    <dxo-header-filter [visible]="true"></dxo-header-filter>
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-filter-panel [visible]="true"></dxo-filter-panel>
    <!--  <dxo-state-storing-->
    <!--    [enabled]="true"-->
    <!--    type="localStorage"-->
    <!--    storageKey="overview"-->
    <!--  ></dxo-state-storing>-->

    <dxi-column
      dataType="string"
      dataField="dtype"
      caption="Aktionstyp"
    ></dxi-column>

    <dxi-column
      dataType="string"
      dataField="description"
      caption="Beschreibung"
    ></dxi-column>

    <dxi-column  sortOrder="asc"
                 dataField="start"
                 caption="Start"
                 dataType="date" [format]="{ date: 'dd.MM.yyyy' }"
    ></dxi-column>

    <dxi-column
      dataType="string"
      dataField="language"
      caption="Sprache"
    >
    </dxi-column>

    <dxi-column
      dataType="string"
      dataField="previousAdvertisingMedium.description"
      caption="Vorjahresaktion"
    >
    </dxi-column>
    <dxi-column
      dataType="string"
      dataField="status"
      caption="Status"
    >
    </dxi-column>
    <dxi-column
      dataType="number"
      dataField="campaign.id"
      caption="Kampagne"
    >
      <dxo-lookup [dataSource]="campaignLookup" displayExpr="name" valueExpr="id"></dxo-lookup>
    </dxi-column>


    <dxo-master-detail [enabled]="false" template="detail"></dxo-master-detail>
    <div *dxTemplate="let cat of 'detail'">
      <div class="row">
        <div class="col-9">
          <div class="master-detail-caption">Bestehende Versionen</div>
          <detail-version [key]="cat.key" [buttons]="false"></detail-version>
        </div>
      </div>
    </div>
    <dxo-paging [pageSize]="8"></dxo-paging>
    <dxo-pager
      [showPageSizeSelector]="true"
      [allowedPageSizes]="[8, 10, 15]"
    ></dxo-pager>
    <dxo-export [enabled]="true" ></dxo-export>
  </dx-data-grid>
</div>
