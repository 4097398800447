import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import {Version} from "../domain/version.model";
import {Observable} from "rxjs";

@Injectable({providedIn: 'root'})
export class VersionService {

  constructor(private httpClient: HttpClient) {

  }

  updateVersion(version: Version): Observable<Version> {
    return this.httpClient.put<Version>(`api/versions`, version);
  }

  copyVersion(data: Version) {
    return this.httpClient.post<Version>(`api/versions/${data.id}/copy-version`, data);
  }



    deleteVersion(key: number): Observable<any> {
        return this.httpClient.delete<any>(`api/versions/${key}`);
    }

  createEmptyVersion(key: number){
    return this.httpClient.post<Version>(`api/versions/create-empty-version`, key);
  }
}
