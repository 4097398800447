import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {CatalogueComponent} from './components/catalogue/catalogue.component';
import {VersionProductComponent} from './components/version-product/version-product.component';
import {NewProductComponent} from './components/new-product/new-product.component';
import {MailingComponent} from "./components/mailing/mailing.component";
import {DiscontinuedProductComponent} from './components/discontinued-product/discontinued-product.component';
import {KpiChartComponent} from './components/charting/kpi-chart.component';
import {NewsletterComponent} from "./components/newsletter/newsletter.component";
import {OverviewComponent} from "./components/overview/overview.component";
import {EventComponent} from "./components/event/event.component";
import {CampaignComponent} from './components/campaign/campaign.component';
import {GanttComponent} from './components/overview/gantt/gantt.component';
import {PennerProductComponent} from "./components/penner-product/penner-product.component";
import {RennerProductComponent} from "./components/renner-product/renner-product.component";
import {ProductComponent} from "./components/product/product.component";
import {ProductKpiChartComponent} from './components/charting/product-kpi-chart/product-kpi-chart.component';
import {LoadingStandComponent} from "./components/loading-stand/loading-stand.component";
import {StammdatenComponent} from "./components/stammdaten/stammdaten.component";
import {WelcomeComponent} from './components/welcome/welcome.component';

import {OrderChannelComponent} from './components/overview/order-channel/order-channel.component';
import {OverviewWebComponent} from './components/overview/overview-web/overview-web.component';
import {OverviewPerComponent} from './components/overview/overview-per/overview-per.component';
import {OverviewTelComponent} from './components/overview/overview-tel/overview-tel.component';
import {PurchaseProductComponent} from './components/purchase-product/purchase-product.component';

import {ProductDetailComponent} from './components/purchase-product/product-detail/product-detail.component';
import {MsalGuard} from '@azure/msal-angular';
import {BrowserUtils} from '@azure/msal-browser';
import {RoleGuardService} from './auth/role-guard';
import {AuthorityEnum} from './domain/user.model';


export const routes: Routes = [
  {
    path: '', component: WelcomeComponent,
    //canActivate: [MsalGuard],
  },
  { path: 'app',
  canActivateChild: [MsalGuard],
  children:[
  {
    path: 'overview',
    component: OverviewComponent,
   canActivate: [RoleGuardService],
    data: {
      roles: [AuthorityEnum.ADMIN, AuthorityEnum.MARKETING]
    }
  },
  {
    path: 'product-detail/:pid',
    component: ProductDetailComponent,
    canActivate: [RoleGuardService],
    data: {
      roles: [AuthorityEnum.ADMIN, AuthorityEnum.PURCHASE]
    }
  },
  {
    path: 'overview-online',
    component: OverviewWebComponent,
    canActivate: [RoleGuardService],
    data: {
      roles: [AuthorityEnum.ADMIN, AuthorityEnum.MARKETING]
    }
  },
  {
    path: 'overview-store',
    component: OverviewPerComponent,
    canActivate: [RoleGuardService],
    data: {
      roles: [AuthorityEnum.ADMIN, AuthorityEnum.MARKETING]
    }
  },
  {
    path: 'overview-phone',
    component: OverviewTelComponent,
    canActivate: [RoleGuardService],
    data: {
      roles: [AuthorityEnum.ADMIN, AuthorityEnum.MARKETING]
    }
  },
  {
    path: 'order-channel',
    component: OrderChannelComponent,
    canActivate: [RoleGuardService],
    data: {
      roles: [AuthorityEnum.ADMIN, AuthorityEnum.MARKETING]
    }
  },
  {
    path: 'overview-gantt',
    component: GanttComponent,
    canActivate: [RoleGuardService],
    data: {
      roles: [AuthorityEnum.ADMIN, AuthorityEnum.MARKETING]
    }
  },
  {
    path: 'campaign',
    component: CampaignComponent,
    canActivate: [RoleGuardService],
    data: {
      roles: [AuthorityEnum.ADMIN, AuthorityEnum.MARKETING]
    }
  },
  {
    path: 'catalogue',
    component: CatalogueComponent,
    canActivate: [RoleGuardService],
    data: {
      roles: [AuthorityEnum.ADMIN, AuthorityEnum.MARKETING]
    }
  },
  {
    path: 'mailing',
    component: MailingComponent,
    canActivate: [RoleGuardService],
    data: {
      roles: [AuthorityEnum.ADMIN, AuthorityEnum.MARKETING]
    }
  },
  {
    path: 'newsletter',
    component: NewsletterComponent,
    canActivate: [RoleGuardService],
    data: {
      roles: [AuthorityEnum.ADMIN, AuthorityEnum.MARKETING]
    }
  },
  {
    path: 'event',
    component: EventComponent,
    canActivate: [RoleGuardService],
    data: {
      roles: [AuthorityEnum.ADMIN, AuthorityEnum.MARKETING]
    }
  },
  {
    path: 'products',
    component: ProductComponent,
    canActivate: [RoleGuardService],
    data: {
      roles: [AuthorityEnum.ADMIN]
    }
  },
  {
    path: 'new-products',
    component: NewProductComponent,
    canActivate: [RoleGuardService],
    data: {
      roles: [AuthorityEnum.ADMIN]
    }
  },
  {
    path: 'version-products/:versionId',
    component: VersionProductComponent,
    canActivate: [RoleGuardService],
    data: {
      roles: [AuthorityEnum.ADMIN]
    }
  },
  {
    path: 'products-kpi',
    component: RennerProductComponent,
    canActivate: [RoleGuardService],
    data: {
      roles: [AuthorityEnum.ADMIN]
    }
  },
  {
    path: 'purchase-products',
    component: PurchaseProductComponent,
    canActivate: [RoleGuardService],
        data: {
          roles: [AuthorityEnum.ADMIN, AuthorityEnum.PURCHASE]
        }
  },
  {
    path: 'loading-stand',
    component: LoadingStandComponent,
    canActivate: [RoleGuardService],
    data: {
      roles: [AuthorityEnum.ADMIN]
    }
  },
  {
    path: 'stammdaten',
    component: StammdatenComponent,
    canActivate: [RoleGuardService],
    data: {
      roles: [AuthorityEnum.ADMIN]
    }
  }
]}];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // Don't perform initial navigation in iframes or popups
      initialNavigation:
        !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup()
          ? "enabledNonBlocking"
          : "disabled", // Set to enabledBlocking to use Angular Universal
    }),
  ],
  exports: [RouterModule]
})
export class AppRoutes { }
