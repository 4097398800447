<ng-container *ngIf="hasVersion">
  <div class="master-detail-caption">Bestehende Versionen</div>
  <dx-data-grid
    id="verGrid"
    [dataSource]="versionDataSource"
    [showBorders]="true"
    [columnAutoWidth]="true"
  >
    <dxo-editing
      mode="row"
      [allowUpdating]="true"
      [allowDeleting]="true"
    >
    </dxo-editing>

    <dxi-column caption="Beschreibung" dataField="description" ></dxi-column>
    <dxi-column caption="Ist Final" dataField="isFinalVersion"  [allowEditing]="false"></dxi-column>
    <dxi-column caption="Versiegelt" dataField="sealed" [allowEditing]="false"></dxi-column>
<!--    <dxi-column *ngIf="buttons" caption="Navigation"  cellTemplate="linkTemplateArtikelPflege">-->
<!--      <div *dxTemplate="let d of 'linkTemplateArtikelPflege'">-->
<!--        <button class="btn btn-sm btn-outline-primary" (click)="routeTo(d)">-->
<!--          zugehörige Artikel pflegen-->
<!--        </button>-->
<!--        <button *ngIf="!d.data.isFinalVersion" class="btn btn-sm btn-outline-primary" (click)="setFinal(d.data)">-->
<!--          Final setzten-->
<!--        </button>-->
<!--        <button *ngIf="d.data.isFinalVersion"  class="btn btn-sm btn-outline-primary" (click)="copyVersion(d.data)">-->
<!--          copy-->
<!--        </button>-->

<!--      </div>-->
<!--    </dxi-column>-->
    <dxi-column [fixed]="true" type="buttons" caption="">
      <dxi-button text="Copy" icon="copy" [visible]="copyButtonVisible"  [onClick]="copyVersion"></dxi-button>
      <dxi-button text="Final setzen" icon="lock" [visible]="setFinalButtonVisible"  [onClick]="setFinal"></dxi-button>
      <dxi-button text="Artikel" icon="activefolder" [onClick]="routeTo"></dxi-button>
      <dxi-button name="edit" icon="edit"></dxi-button>
      <dxi-button [visible]="deleteButtonVisible" name="delete" icon="trash"></dxi-button>
    </dxi-column>

  </dx-data-grid>
</ng-container>
<ng-container *ngIf="!hasVersion">
  <p>Keine Version vorhanden</p>
  <button (click)="createVorschlagVersion()" class="btn btn-primary btn-sm">
  Vorschlag erzeugen
</button>
  <button (click)="createEmptyVersion()" class="btn btn-primary btn-sm">
    Leere Version erzeugen
  </button>
</ng-container>
