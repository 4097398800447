<div ngbAccordion>
  <div ngbAccordionItem [destroyOnHide]="false" [collapsed]="false">
    <h2 ngbAccordionHeader>
      <button ngbAccordionButton>Admin View</button>
    </h2>
    <div ngbAccordionCollapse>
      <div ngbAccordionBody style="max-height: 400px">
        <ng-template>



          <div class="row">
<!--            <div class="col-3">-->
<!--              <div class="card">-->
<!--                <div class="card-body">-->
<!--                  <h6>save current grid state under new name</h6>-->
<!--                  <ng-container *ngIf="currentPatentState">-->

<!--                    <form #f="ngForm" (ngSubmit)="f.form.valid && onNewStateClick()"-->
<!--                          name="form"-->
<!--                          novalidate>-->
<!--                      <div class="row ">-->
<!--                        <div class="col">-->
<!--                          <input #newstate="ngModel"-->
<!--                                 [(ngModel)]="newState.name"-->
<!--                                 [ngClass]="{ 'is-invalid': f.submitted && newstate.errors}"-->
<!--                                 aria-describedby="Kommentar" class="form-control" id="new-state" name="new-state" placeholder="name for new state" required-->
<!--                                 type="text">-->
<!--                          <div *ngIf="f.submitted && newstate.errors" class="invalid-feedback">-->
<!--                            <div *ngIf="newstate.errors['required']">name is required</div>-->
<!--                          </div>-->
<!--                        </div>-->
<!--                        <div class="col">-->
<!--                          <button class="btn btn-primary" type="submit">-->
<!--                            save new state-->
<!--                          </button>-->
<!--                        </div>-->
<!--                      </div>-->

<!--                    </form>-->
<!--                  </ng-container>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
            <div class="col-3">
              <div class="card">
                <div class="card-body">
                  <h6>persisted grid states</h6>
                  <dx-data-grid
                    id="grid"
                    [dataSource]="dataSource"
                    [showBorders]="true"
                    [allowColumnResizing]="true"
                    [allowColumnReordering]="true"
                    [columnAutoWidth]="true"
                    style=" height: 300px;"
                  >
                    <dxo-popup
                      title="New State"
                      [showTitle]="true"
                      [width]="100"
                      [height]="50"
                    >
                    </dxo-popup>
                    <dxo-form>
                      <dxi-item dataField="stateName"></dxi-item>

                    </dxo-form>


                    <dxo-editing   [allowAdding]="true" [allowDeleting]="true"
                                  [useIcons]="true" mode="popup"></dxo-editing>
                    <dxo-scrolling columnRenderingMode="virtual" mode="infinite"></dxo-scrolling>
                    <dxo-paging [enabled]="false"></dxo-paging>
                    <dxo-sorting mode="single"></dxo-sorting>
                    <dxo-selection mode="single"></dxo-selection>
                    <dxo-filter-row [visible]="true"></dxo-filter-row>
                    <dxo-header-filter [visible]="true"></dxo-header-filter>

                    <!--          <dxi-column-->
                    <!--            dataField="gridKey"-->
                    <!--            caption="Grid Key"-->
                    <!--          >-->
                    <!--          </dxi-column>-->
                    <dxi-column
                      dataField="stateName"
                      caption="State Name"

                    >
                      <dxi-validation-rule type="required"></dxi-validation-rule>
                      <dxi-validation-rule
                        type="pattern"
                        [pattern]="stateNamePattern"
                        message="Do not use spaces or special chars"
                      ></dxi-validation-rule>
                    </dxi-column>
                    <dxi-column [fixed]="true" type="buttons" caption="Commands">
                      <dxi-button text="load state to grid" icon="copy"  [onClick]="loadState"></dxi-button>
                      <dxi-button text="save current grid state" icon="save"  [onClick]="saveCurrentState"></dxi-button>
                      <dxi-button name="delete"></dxi-button>
<!--                      <dxi-button text="delete state" icon="trash"  [onClick]="deleteState"></dxi-button>-->
                    </dxi-column>
                  </dx-data-grid>
                </div>
              </div>

            </div>

            <div class="col-6">
              <div class="card">
                <div class="card-body">
                  <h6 class="card-title">user <-> grid state </h6>
                  <dx-data-grid
                    id="userGrid"
                    [dataSource]="userDataSource"
                    [showBorders]="true"
                    [allowColumnResizing]="true"
                    [allowColumnReordering]="true"
                    [columnAutoWidth]="true"
                    (onInitNewRow)="onInitNewRow($event)"
                    (onEditorPreparing)="onEditorPreparing($event)"
                    style="height: 300px;"
                  >

                    <dxo-scrolling columnRenderingMode="virtual" mode="infinite"></dxo-scrolling>
                    <dxo-paging [enabled]="false"></dxo-paging>
                    <dxo-sorting mode="single"></dxo-sorting>
                    <dxo-selection mode="single"></dxo-selection>
                    <dxo-filter-row [visible]="false"></dxo-filter-row>
                    <dxo-header-filter [visible]="true"></dxo-header-filter>
                    <dxo-editing  [allowUpdating]="true" [allowAdding]="true" [allowDeleting]="true"
                                  [useIcons]="true" mode="row"></dxo-editing>


                    <dxi-column
                      dataField="uid"
                      caption="Name"
                      dataType="string"
                      sortOrder="asc"
                      [sortIndex]="1"
                      calculateSortValue="name"
                    >
                      <dxo-lookup [dataSource]="purchaseUsers" [displayExpr]="getName" [valueExpr]="'id'"></dxo-lookup>
                    </dxi-column>
                    <dxi-column
                      dataField="statename"
                      dataType="string"
                      caption="State Name"
                      sortOrder="asc"
                      [sortIndex]="3"
                    >
                      <dxo-lookup [dataSource]="stateLookup"></dxo-lookup>
                    </dxi-column>

                    <dxi-column
                      dataField="defaultstate"
                      caption="is default"
                      dataType="boolean"
                      sortOrder="asc"
                      [sortIndex]="2"

                    ></dxi-column>

                  </dx-data-grid>
                </div>

              </div>
            </div>

          </div>






</ng-template>
      </div>
    </div>
  </div>
</div>


