import {Component, OnInit, ViewChild} from '@angular/core';
import { DxDataGridComponent, DxDataGridModule } from "devextreme-angular";
import DataSource from "devextreme/data/data_source";
import {AdvertisingMediumLanguage, AdvertisingMediumStatus} from "../../domain/enum.model";
import {MailingService} from "../../services/mailing.service";
import {lastValueFrom} from "rxjs";
import {Mailing} from "../../domain/mailing.model";
import { Status, StatusType, FeedbackComponent } from "../interaction/feedback/feedback.component";
import {AdvertisingMedium} from "../../domain/advertisingMedium";
import {Campaign} from "../../domain/campaign.model";
import {CampaignService} from "../../services/campaign.service";
import { DxoSelectionModule, DxoFilterRowModule, DxoHeaderFilterModule, DxoFilterPanelModule, DxoSearchPanelModule, DxoColumnChooserModule, DxoStateStoringModule, DxoEditingModule, DxoFormModule, DxiItemModule, DxoLabelModule, DxiColumnModule, DxiButtonModule, DxiValidationRuleModule, DxoLookupModule, DxoMasterDetailModule, DxoPagingModule, DxoPagerModule } from 'devextreme-angular/ui/nested';
import { DxTemplateModule } from 'devextreme-angular/core';
import { CoverImageComponent } from '../cover-image/cover-image.component';
import { VersionDetailComponent } from '../version-detail/version-detail.component';


@Component({
    selector: 'app-mailing',
    templateUrl: './mailing.component.html',
    styleUrls: ['./mailing.component.scss'],
    standalone: true,
    imports: [FeedbackComponent, DxDataGridModule, DxoSelectionModule, DxoFilterRowModule, DxoHeaderFilterModule, DxoFilterPanelModule, DxoSearchPanelModule, DxoColumnChooserModule, DxoStateStoringModule, DxoEditingModule, DxoFormModule, DxiItemModule, DxoLabelModule, DxiColumnModule, DxiButtonModule, DxiValidationRuleModule, DxoLookupModule, DxTemplateModule, CoverImageComponent, DxoMasterDetailModule, VersionDetailComponent, DxoPagingModule, DxoPagerModule]
})
export class MailingComponent implements OnInit {
  // @ts-ignore
  @ViewChild(DxDataGridComponent) mailGrid: DxDataGridComponent;
  public dataSource: DataSource;

  public languageLookup: AdvertisingMediumLanguage[] = [AdvertisingMediumLanguage.DE, AdvertisingMediumLanguage.CH, AdvertisingMediumLanguage.AT, AdvertisingMediumLanguage.FR];
  public statusLookup: AdvertisingMediumStatus[] = [AdvertisingMediumStatus.ABGESCHLOSSEN, AdvertisingMediumStatus.GEPLANT, AdvertisingMediumStatus.LAUFEND];
  public previousAdvMLookup: AdvertisingMedium[] = [];
  public campaignLookup: Campaign[] = [];
  // @ts-ignore
  public status: Status = undefined;

  constructor(private mailService: MailingService, private campService: CampaignService) {
    this.dataSource = new DataSource({
        key: 'id',
        load: () => {
          return lastValueFrom(this.mailService.getMailings()).then((mailings: Mailing[]) =>{
            this.previousAdvMLookup = mailings.sort((a, b) =>
              a.name.toLowerCase().localeCompare(b.name.toLowerCase())
            );
            this.campService.getCampaigns().subscribe(value => {
              this.campaignLookup = value;
            })
            return {
              data: mailings,
              totalCount: mailings.length
            };
          }).catch((err) => {
            throw Error(err.message);
          });
        },
        insert: (mailing : Mailing) => {
          const _mailing = {...mailing};
          // @ts-ignore
          delete _mailing['__KEY__'];
          if(_mailing.previousAdvertisingMedium) {
            _mailing.previousAdvertisingMedium._class=".Mailing";
          }
          _mailing._class=".Mailing";
          return lastValueFrom(this.mailService.createMailing(_mailing)).then((newMailing : Mailing) => {
            this.status = new Status('Das Mailing wurde erfolgreich angelegt', StatusType.Success);
            return newMailing;
          }).catch((err) => {
            throw err;
          });
        },
        update: (key: number, _new: Mailing) => {
          return this.mailGrid.instance.byKey(key).then((mailing) => {
            const _mailing = {...mailing};
            delete _mailing['__KEY__'];
            if(_mailing.previousAdvertisingMedium) {
              _mailing.previousAdvertisingMedium._class=".Mailing";
            }
            _mailing._class=".Mailing";
            return lastValueFrom(this.mailService.updateMailing(_mailing)).then(() => {
              this.status = new Status('Die Änderungen wurde erfolgreich übernommen', StatusType.Success);
            }).catch((err) => {
              throw Error(err.message);
            });
          });
        },
        remove: (key: number) => {
          return lastValueFrom(this.mailService.deleteMailing(key)).then(() => {
            this.status = new Status('Das Mailing wurde erfolgreich gelöscht', StatusType.Success);
          }).catch((err) => {
            throw Error(err.error.message);
          });
        }
      }
    );
  }

  ngOnInit(): void {
  }

  protected readonly undefined = undefined;
}
