<ng-container *ngIf="isAdmin">
<grid-state (loadStateEvent)="loadStateFromChild($event)" [gridKey]="keyOut" [currentPatentState]="currentState"></grid-state>
</ng-container>


<!--<h2>mystate</h2>-->
<!--<pre>{{myStates|json}}</pre>-->
<!--<h2>currentstate</h2>-->
<!--<pre>{{currentState|json}}</pre>-->
<!--<h2>loadedstate</h2>-->
<!--<pre>{{loadedGridState|json}}</pre>-->

<h6 *ngIf="loadedGridState">Loaded Grid State: {{loadedGridState.stateName}}</h6>
<div>possible states:
  <ng-container *ngFor="let mystate of  myStates">
    <button type="button" class="btn btn-sm btn-outline-secondary m-2" (click)="loadMyState(mystate.statename)">{{mystate.statename}}</button>
  </ng-container>
  <b *ngIf="loadedGridState">Loaded Grid State: {{loadedGridState.stateName}}</b>
</div>
<dx-data-grid
  style=" max-height: 600px;"
  id="purchaseGrid"
  [dataSource]="dataSource"
  [showBorders]="true"
  [allowColumnResizing]="true"
  [allowColumnReordering]="true"
  [columnAutoWidth]="true"
  [filterSyncEnabled]="true"
  (onSelectionChanged)="selectionChanged($event)"
  (onExporting)="onExporting($event)"
>
  <dxo-sorting mode="single"></dxo-sorting>
  <dxo-selection mode="single"></dxo-selection>
  <dxo-filter-row [visible]="true"></dxo-filter-row>
  <dxo-header-filter [visible]="true"></dxo-header-filter>
  <dxo-filter-panel [visible]="true"></dxo-filter-panel>
  <dxo-search-panel
    [visible]="true"
    [width]="240"
    placeholder="Suche..."
  ></dxo-search-panel>
  <dxo-column-chooser [enabled]="true"></dxo-column-chooser>
  <dxo-state-storing [enabled]="true" type="custom"
                     [customSave]="saveState"></dxo-state-storing>
  <dxo-scrolling columnRenderingMode="virtual" mode="infinite"></dxo-scrolling>
  <dxo-paging [enabled]="false"></dxo-paging>
  <dxo-summary>
    <dxi-total-item
      column="productId"
      summaryType="count">
    </dxi-total-item>
  </dxo-summary>

<!--  <dxo-paging [pageSize]="8"></dxo-paging>-->
<!--  <dxo-pager-->
<!--    [showPageSizeSelector]="true"-->
<!--    [allowedPageSizes]="[10,20,50]"-->
<!--  ></dxo-pager>-->

  <dxi-column
    caption="Art.No."
    [fixed]="true"
              cellTemplate="linkTemplate"
              dataField="productId">
    <div *dxTemplate="let d of 'linkTemplate'">
      <button (click)="routeToDetails(d.data)" class="dx-button-link">
<!--        [routerLink]="['/product-detail',d.data.productId]"-->
        {{ d.data.productId }}
      </button>
    </div>
  </dxi-column>
  <dxi-column
    caption="Bestellstop ändern."
    [fixed]="true"
    cellTemplate="orderStopTemplate"
    fixedPosition=right>
    <div *dxTemplate="let d of 'orderStopTemplate'">
      <button (click)="toggleBestellStop(d.data)" class="dx-button">
        <!--        [routerLink]="['/product-detail',d.data.productId]"-->
        {{ d.data.orderStop?'zurücknehmen':'setzen' }}
      </button>
    </div>
  </dxi-column>

<!--  <dxi-column [fixed]="true" fixedPosition=right  type="buttons" caption="Bestellstop ändern">-->
<!--  <dxi-button [text]="isBestellstop" [onClick]="toggleBestellStop"  ></dxi-button>-->
<!--  </dxi-column>-->






  <dxi-column
    dataField="planingRelevance"
    caption="Planungs-Relevant"
    dataType="number"
  >
  </dxi-column>



  <dxi-column
    dataField="productName"
    caption="Bezeichnung"
    dataType="string"
  >
  </dxi-column>

  <dxi-column
    dataField="dispoRelevance"
    caption="Dispo-Relevant"
    dataType="number"
  >
  </dxi-column>

  <dxi-column
    dataField="descriptionLevelOne"
    caption="Category 1"
    dataType="string"
  >
  </dxi-column>

  <dxi-column
    dataField="descriptionLevelTwo"
    caption="Category 2"
    dataType="string"
  >
  </dxi-column>

  <dxi-column
    dataField="descriptionLevelThree"
    caption="Category 3"
    dataType="string"
  >
  </dxi-column>

  <dxi-column
    dataField="groupLevel2"
    caption="CW 2"
    dataType="string"
  >
  </dxi-column>

  <dxi-column
    dataField="orderIntakeTwoYtd"
    caption="ytd2y"
    dataType="number"
  >
  </dxi-column>

  <dxi-column
    dataField="purchasePriority"
    caption="priority"
    dataType="string"
  >
  </dxi-column>

  <dxi-column
    dataField="supplier"
    caption="supplier"
    dataType="string"
  >
  </dxi-column>

  <dxi-column
    dataField="supplierNo"
    caption="supplier No."
    dataType="string"
  >
  </dxi-column>

  <dxi-column
    dataField="purchaseManager"
    caption="purchase Manager"
    dataType="string"
  >
  </dxi-column>

  <dxi-column
    dataField="costPricePriorityItem"
    caption="Unit Cost"
    dataType="number"
    [format]="{ style: 'currency', currency: 'EUR' }"
  >
  </dxi-column>

  <dxi-column
    dataField="supplierPurchasePricePriorityItem"
    caption="Supplier Purchase Price"
    dataType="number"
    [format]="{ style: 'currency', currency: 'EUR' }"
  >
  </dxi-column>

  <dxi-column
    dataField="baseForecast"
    caption="Forecast"
    dataType="number"
  >
  </dxi-column>

  <dxi-column
    dataField="kpiDate"
    caption="KPI Date"
    dataType="date"
    [format]="'dd.MM.yyyy'"
  >
  </dxi-column>

  <dxi-column
    dataField="orderIntakeAmountActual"
    caption="Ist VK kum."
    dataType="number"
  >
  </dxi-column>



  <dxi-column
    caption="Lfd. Jahr Plan Ges."
    dataField="quantityPlanned"
    dataType="number"
  >
  </dxi-column>

  <dxi-column
    dataField="amountInStock"
    caption="Lagerbest. Möbel u. Komp."
    dataType="number">

  </dxi-column>
  <dxi-column
    dataField="amountInStock1A"
    caption="Lagerbest. 1A"
    dataType="number"
  >

  </dxi-column>

  <dxi-column
    caption="Lagerbestand I (%) 1A+2A"
    dataField='lagerbestand1'
    dataType="number">
    <dxo-format type = "percent" [precision]="2"></dxo-format>

  </dxi-column>

  <dxi-column
    caption="Lagerbestand II (%) inkl. Transit&Prod"
    dataField='lagerbestand2'
    dataType="number">
    <dxo-format type = "percent" [precision]="2"></dxo-format>
  </dxi-column>

  <dxi-column
    dataField="amountSoldPrevYearYtd"
    caption="Ist VK YTD VJ"
    dataType="number">
  </dxi-column>


  <dxi-column
    dataField="amountSoldPrevYear"
    caption="Ist VK VJ Gesamt"
    dataType="number"
  >

  </dxi-column>
  <dxi-column
    dataField="amountInTransit"
    caption="versendete Möbel"
    dataType="number"
  >
  </dxi-column>

  <dxi-column
    dataField="amountOrdered"
    caption="bestellte Möbel"
    dataType="number"
  >
  </dxi-column>

  <dxi-column
    dataField="amountInStockOfSupplier"
    caption="Bestand Prod. Lager"
    dataType="number"
  >
  </dxi-column>

  <dxi-column
    dataField="istVkYtdProPlanAktuell"
    caption="Ist VK YTD/Plan aktuell"
    dataType="number"
  >
    <dxo-format type = "percent" [precision]="2"></dxo-format>
  </dxi-column>

  <dxi-column
    dataField="remainingPlannedOrderIntakeAmount"
    caption="Rest VK nach Plan aktuell"
    dataType="number"
  >
  </dxi-column>



  <dxi-column
    dataField="lagerbestand1AProRestVK"
    caption="LB 1A/Rest VK"
    dataType="number"
  >
    <dxo-format type = "percent" [precision]="2"></dxo-format>
  </dxi-column>

  <dxi-column
    dataField="lagerbestand1APlusAWProRestVK"
    caption="LB 1A + AW/Rest VK"
    dataType="number"
  >
    <dxo-format type = "percent" [precision]="2"></dxo-format>
  </dxi-column>

  <dxi-column
    dataField="lagerbestand1APlusSWProRestVK"
    caption="LB 1A + SW/Rest VK"
    dataType="number"
  >
    <dxo-format type = "percent" [precision]="2"></dxo-format>
  </dxi-column>








  <dxi-column
    dataField="range"
    caption="Reichweite in Wochen"
    dataType="number"
  >

  </dxi-column>

  <dxi-column
    dataField="salesRank"
    caption="Sales Rank"
    dataType="number"
    sortOrder="asc"
    >

  </dxi-column>

  <dxi-column
    dataField="orderStop"
    dataType="boolean"
    caption="OrderStop"
    [allowHeaderFiltering]="false"
  >
  </dxi-column>

  <dxi-column
    dataField="specialItem"
    dataType="boolean"
    caption="Sonderartikel"
  >
  </dxi-column>


  <dxo-export [enabled]="true" [allowExportSelectedData]="true"></dxo-export>
</dx-data-grid>
<!--<button (click)="onStateResetClick()">State reset</button>-->






