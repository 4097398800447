import {Component, OnInit, ViewChild} from '@angular/core';
import { DxDataGridComponent, DxDataGridModule } from "devextreme-angular";
import DataSource from "devextreme/data/data_source";
import {AdvertisingMediumLanguage, AdvertisingMediumStatus} from "../../domain/enum.model";
import {lastValueFrom} from "rxjs";
import {NewsletterService} from "../../services/newsletter.service";
import {Newsletter} from "../../domain/newsletter.model";
import { Status, StatusType, FeedbackComponent } from "../interaction/feedback/feedback.component";
import {AdvertisingMedium} from "../../domain/advertisingMedium";
import {Campaign} from "../../domain/campaign.model";
import {CampaignService} from "../../services/campaign.service";
import { NgbModal, NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import {NotifyService} from '../../services/notify.service';
import {NlReferrerComponent} from './nl-referrer/nl-referrer.component';
import {Referrer} from '../../domain/referrer.model';
import { DxoSelectionModule, DxoFilterRowModule, DxoHeaderFilterModule, DxoFilterPanelModule, DxoSearchPanelModule, DxoColumnChooserModule, DxoStateStoringModule, DxoEditingModule, DxoFormModule, DxiItemModule, DxoLabelModule, DxiColumnModule, DxiButtonModule, DxiValidationRuleModule, DxoLookupModule, DxoPagingModule, DxoPagerModule } from 'devextreme-angular/ui/nested';
import { DxTemplateModule } from 'devextreme-angular/core';
import { CoverImageComponent } from '../cover-image/cover-image.component';

/**
 * Represents the Newsletter Component.
 * This component is responsible for handling newsletter-related operations, such as displaying newsletters in a data grid,
 * creating new newsletters, updating existing ones, and deleting newsletters.
 * It also provides a method for editing referrers data and a helper method for formatting referrer names into a nice list.
 */
@Component({
    selector: 'app-newsletter',
    templateUrl: './newsletter.component.html',
    styleUrls: ['./newsletter.component.scss'],
    standalone: true,
    imports: [FeedbackComponent, DxDataGridModule, DxoSelectionModule, DxoFilterRowModule, DxoHeaderFilterModule, DxoFilterPanelModule, DxoSearchPanelModule, DxoColumnChooserModule, DxoStateStoringModule, DxoEditingModule, DxoFormModule, DxiItemModule, DxoLabelModule, DxiColumnModule, DxTemplateModule, NgbTooltip, DxiButtonModule, DxiValidationRuleModule, DxoLookupModule, CoverImageComponent, DxoPagingModule, DxoPagerModule]
})
export class NewsletterComponent implements OnInit {
  // @ts-ignore
  @ViewChild(DxDataGridComponent) newsletterGrid: DxDataGridComponent;
  public dataSource: DataSource;

  public languageLookup: AdvertisingMediumLanguage[] = [AdvertisingMediumLanguage.DE, AdvertisingMediumLanguage.CH, AdvertisingMediumLanguage.AT, AdvertisingMediumLanguage.FR];
  public statusLookup: AdvertisingMediumStatus[] = [AdvertisingMediumStatus.ABGESCHLOSSEN, AdvertisingMediumStatus.GEPLANT, AdvertisingMediumStatus.LAUFEND];
  public previousAdvMLookup: AdvertisingMedium[] = [];
  public campaignLookup: Campaign[] = [];
  // @ts-ignore
  public status: Status = undefined;

  constructor(private newsletterService: NewsletterService, private campService: CampaignService,  private modalService: NgbModal, private notifyService: NotifyService) {
    this.dataSource = new DataSource({
        key: 'id',
        load: () => {
          return lastValueFrom(this.newsletterService.getNewsletter()).then((newsletters: Newsletter[]) =>{
            this.previousAdvMLookup = newsletters.sort((a, b) =>
              a.name.toLowerCase().localeCompare(b.name.toLowerCase())
            );
            this.campService.getCampaigns().subscribe(value => {
              this.campaignLookup = value;
            })
            return {
              data: newsletters,
              totalCount: newsletters.length
            };
          }).catch((err) => {
            throw Error(err.message);
          });
        },
        insert: (newsletter : Newsletter) => {
          const _newsletter = {...newsletter};
          // @ts-ignore
          delete _newsletter['__KEY__'];
          if(_newsletter.previousAdvertisingMedium) {
            _newsletter.previousAdvertisingMedium._class=".Newsletter";
          }
          _newsletter._class=".Newsletter";
          return lastValueFrom(this.newsletterService.createNewsletter(_newsletter)).then((newNewsletter : Newsletter) => {
            this.status = new Status('Der Newsletter wurde erfolgreich angelegt', StatusType.Success);
            return newNewsletter;
          }).catch((err) => {
            throw err;
          });
        },
        update: (key: number, _new: Newsletter) => {
          return this.newsletterGrid.instance.byKey(key).then((newsletter) => {
            const _newsletter = {...newsletter};
            delete _newsletter['__KEY__'];
            if(_newsletter.previousAdvertisingMedium){
              _newsletter.previousAdvertisingMedium._class=".Newsletter";
            }
            _newsletter._class=".Newsletter";
            return lastValueFrom(this.newsletterService.updateNewsletter(_newsletter)).then(() => {
              this.status = new Status('Die Änderungen wurde erfolgreich übernommen', StatusType.Success);

            }).catch((err) => {
              throw Error(err.message);
            });
          });
        },
        remove: (key: number) => {
          return lastValueFrom(this.newsletterService.deleteNewsletter(key)).then(() => {
            this.status = new Status('Der Newsletter wurde erfolgreich gelöscht', StatusType.Success);
          }).catch((err) => {
            throw Error(err.error.message);
          });
        }
      }
    );
  }

  /**
   * Opens a modal to edit referrers data.
   *
   * @param {any} rowData - The data of the referrer to be edited.
   * @return {void}
   */
  public editReferrers(rowData: any): void {
    const modalRef = this.modalService.open(NlReferrerComponent);
    modalRef.componentInstance.rowData = rowData;
    modalRef.result.then(() => {
      this.notifyService.toastInfo("Referrer edited")
    });
  }

  ngOnInit(): void {
  }

  niceList(referrer: Referrer[] | undefined) {
    if(!referrer) return '';
    const referrerNames = referrer.map(r => r.name);
    return referrerNames.join(', ');
  }
}
