<nav class="navbar navbar-expand-lg bg-light bg-body-tertiary shadow p-0">
  <div class="container-fluid">
    <img class="navbar-brand p-0 pe-2 m-0" [routerLink]="[navLinks[0].path]" src="main/webapp/assets/images/garpa.svg" style="height: 45px">
    <button class="navbar-toggler"
            type="button"
            (click)="isMenuCollapsed = !isMenuCollapsed"
            aria-label="Navigation"
            data-target="#navbarContent"
            aria-controls="navbarContent"
            [attr.aria-expanded]="!isMenuCollapsed">
      &#9776;
    </button>
    <div [ngbCollapse]="isMenuCollapsed" class="collapse navbar-collapse" id="navbarContent">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        <ng-container *ngFor="let link of navLinks">

          <li *ngIf="link.subLinks.length===0" class="nav-item">
            <a [routerLink]="[link.path]" class="nav-link">{{link.text}}</a>
          </li>

          <li *ngIf="link.subLinks.length>0" class="nav-item" ngbDropdown>
            <a [id]="'id' + link.id" class="nav-link" ngbDropdownToggle role="button" tabindex="0"> {{link.text}} </a>
            <div class="dropdown-menu" ngbDropdownMenu>
              <a *ngFor="let sublink of link.subLinks" [routerLink]="['app',sublink.path]"
                 ngbDropdownItem>{{sublink.text}}</a>
            </div>
          </li>
        </ng-container>
        <li>
          <a class="btn btn-outline-secondary" href="#" (click)="logout()">
            <i class="bi bi-box-arrow-right" aria-hidden="true"></i> Logout
          </a>
        </li>
      </ul>

    </div>
    <div> {{ account?.username }}</div>
  </div>
</nav>

