import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import {Observable} from "rxjs";
import {Event} from "../domain/event.model";
import {EventOrt} from "../domain/eventOrt.model";

@Injectable({providedIn: 'root'})
export class EventService {

  constructor(private httpClient: HttpClient) {
  }

  getEvents(): Observable<Event[]> {
    return this.httpClient.get<Event[]>(`api/events`)
  }

  getEventOrts(): Observable<EventOrt[]> {
    return this.httpClient.get<EventOrt[]>(`api/event-orts`)
  }

  createEvent(event: Event): Observable<Event> {
    return this.httpClient.post<Event>(`api/events`, event);
  }

  updateEvent(event: Event): Observable<Event> {
    return this.httpClient.put<Event>(`api/events`, event);
  }

  deleteEvent(key: number): Observable<any> {
    return this.httpClient.delete<any>(`api/events/${key}`);
  }
}
