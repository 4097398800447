import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import {Observable} from "rxjs";
import {Version} from "../domain/version.model";
import {Newsletter} from "../domain/newsletter.model";

@Injectable({providedIn: 'root'})
export class NewsletterService {

  constructor(private httpClient: HttpClient) { }

  getNewsletter(): Observable<Newsletter[]> {
    return this.httpClient.get<Newsletter[]>(`api/newsletters`);
  }
  createNewsletter(newsletter: Newsletter): Observable<Newsletter> {
    return this.httpClient.post<Newsletter>(`api/newsletters`, newsletter);
  }
  updateNewsletter(newsletter: Newsletter): Observable<Newsletter> {
    return this.httpClient.put<Newsletter>(`api/newsletters`, newsletter);
  }
  deleteNewsletter(key: number): Observable<any> {
    return this.httpClient.delete<any>(`api/newsletters/${key}`);
  }
  getNewsletterVersions(key: number | undefined): Observable<Version[]> {
    return this.httpClient.get<Version[]>(`api/advertisingMedium/${key}/versions`);
  }
}
