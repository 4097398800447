import { Component, Input } from '@angular/core';
import {timer} from 'rxjs';
import { NgIf, NgClass } from '@angular/common';

@Component({
    selector: 'dbc-feedback',
    templateUrl: './feedback.component.html',
    standalone: true,
    imports: [NgIf, NgClass]
})
export class FeedbackComponent {

  @Input()
  set status(status: Status) {
    this._status = status;
  }

  get status(): Status {
    return this._status;
  }

  // @ts-ignore
  private _status: Status = undefined;


  @Input()
  set error(error: string) {
    this._error = error;
    // @ts-ignore
    document.getElementById('error').focus();
  }

  get error() {
    return this._error;
  }

  // @ts-ignore
  private _error: string = undefined;

  @Input() customStyle = undefined;

  constructor() {
  }

  protected readonly Status = Status;
  //protected readonly StatusType = StatusType;
}

export class Status {
  constructor(message: string, type: StatusType) {
    this.message = message;
    this.type = type;
    setTimeout(() =>
      {
        this.type = StatusType.Nope;
      },
      5000);
  }
  message: string;
  type: StatusType;
}

export const enum StatusType {
  Info = 'info',
  Success = 'success',
  Nope = 'nope'
}
