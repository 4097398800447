import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import {Observable} from "rxjs";
import {Campaign} from "../domain/campaign.model";


@Injectable({providedIn: 'root'})
export class CampaignService {

  constructor(private httpClient: HttpClient) {

  }

  getCampaigns(): Observable<Campaign[]> {
    return this.httpClient.get<Campaign[]>(`api/campaigns`);
  }
  createCampaign(campaign: Campaign): Observable<Campaign> {
    return this.httpClient.post<Campaign>(`api/campaigns`, campaign);
  }
  updateCampaign(campaign: Campaign): Observable<Campaign> {
    return this.httpClient.put<Campaign>(`api/campaigns`, campaign);
  }
  deleteCampaign(key: number): Observable<any> {
    return this.httpClient.delete<any>(`api/campaigns/${key}`);
  }

}
