import {AccountInfo} from '@azure/msal-common';

export interface GraphUser extends AccountInfo  {
  displayName: string;
  givenName: string;
  id: string;
  mail: string;
  surname: string;
  userPrincipalName: string;
  idTokenClaims?: {
    roles?: string[];
  }
}

export interface AppUser {
  domainLogin: string;
  email: string;
  enabled: boolean;
  firstName: string;
  lastName: string;
  roles: AppRole[];
  uiGridStateUsers: any
}

export interface AppRole {
  name: AuthorityEnum;
}

export enum AuthorityEnum{
  ADMIN='ADMIN',
  PURCHASE='PURCHASE',
  MARKETING='MARKETING'
}
