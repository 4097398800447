import {Component, OnInit, ViewChild} from '@angular/core';
import { DxDataGridComponent, DxDataGridModule } from 'devextreme-angular';
import DataSource from 'devextreme/data/data_source';
import {lastValueFrom} from 'rxjs';
import {ActiveProduct, NewProduct} from '../../domain/product.model';
import {DxoFormSimpleItem} from 'devextreme-angular/ui/nested/base/form-simple-item';
import {ProductService} from '../../services/product-service.service';
import { Status, StatusType, FeedbackComponent } from "../interaction/feedback/feedback.component";
import { DxoSelectionModule, DxoFilterRowModule, DxoFilterPanelModule, DxoStateStoringModule, DxoEditingModule, DxoFormModule, DxiItemModule, DxiColumnModule, DxoLookupModule, DxoPagingModule, DxoPagerModule } from 'devextreme-angular/ui/nested';

@Component({
    selector: 'app-new-product',
    templateUrl: './new-product.component.html',
    standalone: true,
    imports: [FeedbackComponent, DxDataGridModule, DxoSelectionModule, DxoFilterRowModule, DxoFilterPanelModule, DxoStateStoringModule, DxoEditingModule, DxoFormModule, DxiItemModule, DxiColumnModule, DxoLookupModule, DxoPagingModule, DxoPagerModule]
})
export class NewProductComponent  implements OnInit {

  // @ts-ignore
  @ViewChild(DxDataGridComponent) newProductGrid: DxDataGridComponent;
  public dataSource: DataSource;
  private isNewRow: boolean=false;
  public activeProducts: ActiveProduct[] = []
  // @ts-ignore
  public status: Status = undefined;
  constructor(private productService: ProductService) {
    this.customizeForm = this.customizeForm.bind(this);

    this.productService.getActiveProducts().subscribe(value => {
      this.activeProducts = value;
    })

    this.dataSource = new DataSource({
      key: 'id',
      load: () => {
        return lastValueFrom(this.productService.getNewProducts()).then((newProducts: NewProduct[]) => {
          return {
            data: newProducts,
            totalCount: newProducts.length
          };
        }).catch((err) => {
          throw Error(err.message);
        });
      },
      insert: (newProduct: NewProduct) => {
        const _product = { ...newProduct };
        // @ts-ignore
        delete _product['__KEY__'];
        _product._class=".NewProduct";
        if(_product.realProduct) {
          _product.realProduct._class=".ActiveProduct";
        }
        return lastValueFrom(this.productService.createNewProducts(_product)).then((newProduct: NewProduct) => {
          this.status = new Status('Das Produkt wurde erfolgreich angelegt', StatusType.Success);
          return newProduct;
        }).catch((err) => {
          throw err;
        });
      },
      update: (key: string, _new: NewProduct) => {
        return this.newProductGrid.instance.byKey(key).then((catalogue) => {
          const _product = { ...catalogue };
          delete _product['__KEY__'];
          _product._class=".NewProduct";
          if(_product.realProduct) {
            _product.realProduct._class=".ActiveProduct";
          }
          return lastValueFrom(this.productService.updateNewProducts(_product)).then(() => {
            this.status = new Status('Die Änderungen wurde erfolgreich übernommen', StatusType.Success);
          }).catch((err) => {
            throw Error(err.message);
          });
        });
      },
      remove: (key: string ) => {
        return lastValueFrom(this.productService.deleteNewProduct(key)).then(() => {
          this.status = new Status('Das Produkt wurde erfolgreich gelöscht', StatusType.Success);
        }).catch((err) => {
          throw Error( err.error.message);
        });
      }
    });
  }
  ngOnInit(): void {
   // this.dataSource.reload();
  }

  customizeForm(item: DxoFormSimpleItem) {
    if (this.isNewRow) { // Adding
      if (item.dataField==='id') {
        item.editorOptions = {
          readOnly: false
        }
      }
    }
    else { // Editing
      item.editorOptions = {
        readOnly: true
      }
      if (item.dataField==='price' || item.dataField==='grossPrice')
      {
        item.editorOptions = {
          readOnly: true,
          format: {type:'currency',currency:'EUR'},
          placeholder: 'in Euro'
        }
      }
      if (item.dataField==='realProduct.id')
      {
        item.editorOptions = {
          readOnly: false,
          placeholder: 'Auswahl'
        }
      }
    }
  }

  initNewRow(e:any) {
    this.isNewRow = true;
  }

  editingStart(e: any) {
    this.isNewRow = false;
  }
}
