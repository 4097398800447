import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs';
import {Catalogue} from '../domain/catalogue.model';
import {Version} from '../domain/version.model';
import {NewProduct, Product} from '../domain/product.model';

@Injectable({providedIn: 'root'})
export class VersionProductService {
  constructor(private httpClient: HttpClient) {
  }
  getVersionWithProducts(versionId: number): Observable<Version>  {
    return this.httpClient.get<Version>(`api/versions/${versionId}/products`);
  }



  addProductsToVersion(version: Version, products: string[]): Observable<any> {
    return this.httpClient.post<any>(`api/versions/${version.id}/add-products`,products);
  }

  removeProductsFromVersion(version: Version, products: string[]): Observable<any> {
    return this.httpClient.post<any>(`api/versions/${version.id}/remove-products`,products);
  }
}
