export const environment = {
  production: true,
  msalConfig: {
    // //dbconn
    // auth: {
    //   clientId: 'bd751864-1406-4689-888d-2ca1bb21d7bb',
    //   authority: 'https://login.microsoftonline.com/common',
    // },
    //garpa
    auth: {
      clientId: '59a2d3dc-ab95-4bf0-aac6-f649065ecceb',
      authority: 'https://login.microsoftonline.com/common',
      redirectUri: 'https://tools.dbconn.net/',
      postLogoutRedirectUri: 'https://tools.dbconn.net/',
    },
  },
  apiConfig: {
    scopes: ['user.read'],
    uri: 'https://graph.microsoft.com/v1.0/me',
  },
};
