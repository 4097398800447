import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { EChartsOption } from 'echarts';
import { KpiService } from '../../services/kpi.service';
import { AdvertisingMediumService } from '../../services/advertising-medium.service';
import { AdvertisingMedium } from '../../domain/advertisingMedium';
import { formatCurrency, NgFor } from '@angular/common';
import {
  AdvertisingMediumKpi,
  color,
  IntakeChannel,
  KpiLineChartDTO,
  KpiPerspective,
} from '../../domain/kpi.mpodel';

import * as echarts from 'echarts/core';
import {
  ChartSettingService,
  DateRange,
  Zeitraum,
} from './chart-setting.service';
import {
  NgxEchartsDirective,
  NgxEchartsModule,
  provideEcharts,
} from 'ngx-echarts';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-kpi-chart',
  templateUrl: './kpi-chart.component.html',
  standalone: true,
  imports: [NgxEchartsDirective, FormsModule, NgFor],
  providers: [provideEcharts()],
})
export class KpiChartComponent implements OnChanges {
  @Input() avm: AdvertisingMedium[] | undefined;
  @Input() showNegative = false;
  @Input() perpective: KpiPerspective = KpiPerspective.OVERVIEW;
  @Input() channel: IntakeChannel = IntakeChannel.ALL;

  public kpiOptions: EChartsOption = {};
  public ready = false;
  umsatzAndPlanData: KpiLineChartDTO | undefined;
  admMarklineData: any = [];
  admPlansMap = new Map<Date, number>();
  admPlansData: any[] = [];
  admIstData = [];
  smooth = 0.2;
  kumulierteDarstellung = false;
  options = this.settingService.avaibleOptions;
  selectedOption: Zeitraum = this.options[0];
  private avms: AdvertisingMedium[] | undefined;
  private lastUmsatzKpis: Map<number, AdvertisingMediumKpi> = new Map<
    number,
    AdvertisingMediumKpi
  >();

  constructor(
    private kpiService: KpiService,
    private admService: AdvertisingMediumService,
    private settingService: ChartSettingService,
  ) {
    this.settingService
      .rangeSubject$()
      .subscribe((value) => (this.selectedOption = value));
    this.settingService.getKumuliertSubject$().subscribe((value) => {
      this.kumulierteDarstellung = value;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['avm']) this.avms = changes['avm'].currentValue;
    if (changes['perpective'])
      this.perpective = changes['perpective'].currentValue;
    if (changes['showNegative'])
      this.showNegative = changes['showNegative'].currentValue;
    if (changes['channel']) this.channel = changes['channel'].currentValue;

    this.kpiService.getLastAdmUmsatzKpis().subscribe((value) => {
      value.forEach((kpi) => {
        this.lastUmsatzKpis.set(kpi.id.advertisingMediumId, kpi);
      });
      this.createChartOptions();
    });
  }
  initOpts = {
    renderer: 'svg',
    width: 300,
    height: 300,
  };

  private createMarkerLineAndADMBarchart() {
    this.admMarklineData = [];
    this.admPlansData = [];
    this.admIstData = [];
    let d = new Date(2000, 0, 1);
    const von = this.selectedOption.dateRange.von;
    const bis = this.selectedOption.dateRange.bis;
    let abs = 0;
    let gesamtUmsatz = 0;
    if (this.avms === undefined) {
      return;
    }

    // @ts-ignore
    this.avms.forEach((adm) => {
      // @ts-ignore
      const catType = adm['catalogueType'] ? adm['catalogueType']['id'] : 0;
      // @ts-ignore
      if (adm.name && adm.start && catType !== 2) {
        const v = new Date(adm.start);
        let ist = 0;
        if (this.lastUmsatzKpis.has(adm.id)) {
          //@ts-ignore
          ist = this.lastUmsatzKpis.get(adm.id)?.totalOrderIntake;
        }
        const umsatz = adm.plannedSalesAmount;
        if (v < von || v > bis) {
          return;
        }
        if (d === v) {
          abs = abs + 100;
          gesamtUmsatz = gesamtUmsatz + umsatz;
        } else {
          abs = 0;
          const gesamtUmsatz = 0;
        }
        if (umsatz && umsatz > 0) {
          this.admPlansData.push({
            name: adm.name,
            value: [adm.start, umsatz],
          });
        }
        if (ist && ist > 0) {
          // @ts-ignore
          this.admIstData.push({ name: adm.name, value: [adm.start, ist] });
        }

        this.admMarklineData.push({
          name: adm.name,
          xAxis: adm.start,
          label: { rotate: 90, formatter: '{b}', distance: [abs, 0] },
        });
        d = v;
      }
    });
  }

  createChartOptions() {
    this.createMarkerLineAndADMBarchart();
    //Zeitachse abhängig von den ausgewählten Daten wird erst mal nicht umgesetz.
    switch (this.perpective) {
      case KpiPerspective.OVERVIEW: {
        this.kpiService
          .getKpiUmsatz(
            this.selectedOption.dateRange.von,
            this.selectedOption.dateRange.bis,
            this.channel,
          )
          .subscribe((chart) => {
            this.umsatzAndPlanData = chart;
            this.setupChart();
            this.ready = true;
          });
        break;
      }
      case KpiPerspective.ORDER_CHANNEL: {
        this.kpiService
          .getKpiOrderChannelUmsatz(
            this.selectedOption.dateRange.von,
            this.selectedOption.dateRange.bis,
          )
          .subscribe((chart) => {
            this.umsatzAndPlanData = chart;
            this.setupChart();
            this.ready = true;
          });
        break;
      }
      default:
    }
  }
  setupChart() {
    const chart = this.umsatzAndPlanData;

    if (chart !== undefined) {
      // chart.kpiSeries[0].data.forEach(value => {
      //
      //   let currentDate = new Date(value[0]);
      //   // in dieser Schleife über alle Dates bauen wir die BarCharts für die AVMs auf:
      //   if (this.admPlansMap.has(currentDate)) {
      //     this.admPlansData.push([value,this.admPlansMap.get(currentDate)?.valueOf()])
      //   } else {
      //     this.admPlansData.push([value[0],10000]);
      //   }
      // })
      if (this.kumulierteDarstellung) {
        chart.kpiSeries.forEach((serie) => {
          let year = 0;
          let ums = 0;
          serie.data.forEach((value) => {
            const currentDate = new Date(value[0]);
            if (year !== currentDate.getFullYear()) {
              year = currentDate.getFullYear();
              ums = 0;
            }

            const currentUms = value[1];
            ums = ums + currentUms;
            value[1] = ums;
          });
        });

        // let year = 0;
        // let ums = 0;
        // chart.kpiSeries[0].data.forEach(value => {
        //   let currentDate = new Date(value[0]);
        //     if (year !== currentDate.getFullYear()) {
        //         year = currentDate.getFullYear();
        //         ums = 0;
        //     }
        //
        //     let currentUms = value[1];
        //     ums = ums + currentUms;
        //     value[1] = ums;
        // })
        // ums = 0;
        // chart.kpiSeries[1].data.forEach(value => {
        //     let currentDate = new Date(value[0]);
        //     if (year !== currentDate.getFullYear()) {
        //         year = currentDate.getFullYear();
        //         ums = 0;
        //     }
        //
        //     let currentUms = value[1] ;
        //     ums = ums + currentUms;
        //     value[1] = ums;
        // })
      }

      const admIstSerie = {
        type: 'bar',
        name: 'medium: order intake',
        data: this.admIstData,
        seriesLayoutBy: 'row',

        //color: color.blue,
        barWidth: 10,
        itemStyle: {
          borderRadius: 2,
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            { offset: 0, color: '#14c8d4' },
            { offset: 1, color: '#43eec6' },
          ]),
        },
        xAxisIndex: 0,
        yAxisIndex: this.kumulierteDarstellung ? 0 : 1,
      };

      const admPlanSerie = {
        type: 'bar',
        name: 'medium: planned order intake',
        data: this.admPlansData,
        seriesLayoutBy: 'row',
        color: color.light_yellow,
        barGap: '-100%',
        barWidth: 10,
        z: -12,
        itemStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            { offset: 0, color: 'rgba(212,20,20,0.7)' },
            //{ offset: 0.2, color: 'rgba(212,20,20,0.4)' },
            { offset: 1, color: 'rgba(212,20,20,0.2)' },
          ]),
        },
        xAxisIndex: 0,
        yAxisIndex: this.kumulierteDarstellung ? 0 : 1,
      };

      const admPlanSerie2 = {
        name: 'xxx_dotted',
        type: 'pictorialBar',
        symbol: 'rect',
        itemStyle: {
          color: '#ffffff',
        },
        symbolRepeat: true,
        symbolSize: [12, 4],
        symbolMargin: 1,
        data: this.admPlansData,
        z: -10,
        xAxisIndex: 0,
        yAxisIndex: this.kumulierteDarstellung ? 0 : 1,
      };

      // wir wollen keine symbols und die markline an plan hängen
      let i = -1;
      chart.kpiSeries.forEach((value, index) => {
        // @ts-ignore
        chart.kpiSeries[index]['symbol'] = 'none';
        chart.kpiSeries[index].smooth = this.smooth;

        if (chart.kpiSeries[index].color === 'palette') {
          delete chart.kpiSeries[index].color;
        }

        if (value.name === 'plan') {
          //chart.kpiSeries[index].color= '2F4F4F';
          i = index;
        }
      });
      if (i === -1) i = 0;
      let loop = new Date(this.selectedOption.dateRange.von);
      const xdata = [];
      while (loop <= this.selectedOption.dateRange.bis) {
        const newDate = loop.setDate(loop.getDate() + 1);

        loop = new Date(newDate);
        xdata.push([loop.toLocaleDateString('en-CA'), 0]);
      }

      const xx = {
        data: xdata,
        name: 'medium',
        seriesLayoutBy: 'row',
        type: 'line',
        symbol: 'none',
        color: '2F4F4F',
        width: 0,
        z: -3,
        markLine: {
          lineStyle: {
            width: 1,
            color: '2F4F4F',
            cap: 'round',
          },
          data: this.admMarklineData,
        },
      };

      const o: any[] = chart.kpiSeries;

      o.push(admIstSerie);
      o.push(admPlanSerie);
      o.push(admPlanSerie2);

      o.push(xx);

      const _legend = { data: [] };
      o.forEach((value) => {
        if (value['name'] && !(value['name'] as string).startsWith('xxx_')) {
          // @ts-ignore
          _legend.data.push({ name: value['name'] });
        }
      });

      // @ts-ignore
      this.kpiOptions = {
        title: {
          text: '', //`${chart.kpiName}`
        },
        legend: _legend,
        toolbox: {
          feature: {
            saveAsImage: {},
          },
        },
        tooltip: [
          //     {trigger: 'item',
          //     formatter: function (params: any) {
          //         let out = ''
          //         if (params.componentType === 'series') {
          //             if(params.componentSubType==='bar'){
          //               out = out + params.name + ': ' + formatCurrency(+params.value[1], 'de', '€', 'EUR', '2.0-0') + '<br>'
          //
          //             } else {
          //               out = out + params.seriesName + ': ' + formatCurrency(+params.value[1], 'de', '€', 'EUR', '2.0-0') + '<br>'
          //             }
          //         } else if (params.componentType === 'markLine') {
          //             out = out + params.name
          //         }
          //         return out;
          //     },
          //     axisPointer: {
          //         animation: false
          //     }
          //
          // },}
          {
            trigger: 'axis',
            formatter: function (params: any) {
              let out = params[0].axisValueLabel.substring(0, 10) + '<br>';
              // @ts-ignore
              params.forEach((param) => {
                if (
                  param.componentType === 'series' &&
                  param.seriesName !== 'medium' &&
                  !(param.seriesName as string)?.startsWith('xxx_')
                ) {
                  if (param.componentSubType === 'bar') {
                    out =
                      out +
                      param.name +
                      ': ' +
                      param.seriesName.replace('medium:', '') +
                      ': ' +
                      formatCurrency(
                        +param.value[1],
                        'de',
                        '€',
                        'EUR',
                        '2.0-0',
                      ) +
                      '<br>';
                  } else {
                    out =
                      out +
                      param.seriesName +
                      ': ' +
                      formatCurrency(
                        +param.value[1],
                        'de',
                        '€',
                        'EUR',
                        '2.0-0',
                      ) +
                      '<br>';
                  }
                } else if (param.componentType === 'markLine') {
                  out = out + param.name;
                }
              });

              return out;
            },

            axisPointer: {
              animation: true,
            },
          },
        ],
        dataset: {
          // Provide a set of data.
          //source: data
        },
        // Declare an x-axis (category axis).
        // The category map the first column in the dataset by default.
        xAxis: {
          type: 'time',
        },
        // Declare a y-axis (value axis).
        yAxis: [
          {
            name: 'plan: order intake',
            gridIndex: 0,
            type: 'value',
            scale: true,
            min: this.showNegative ? undefined : 0,
            position: 'left',
          },
          {
            name: this.kumulierteDarstellung
              ? ''
              : 'medium: planned order intake',
            type: 'value',
            scale: true,
            gridIndex: 0,
            min: 0,
            position: 'right',
          },
        ],

        grid: {
          top: 100,
          left: 100,
          right: 100,
          bottom: 100,
        },

        // Declare several 'bar' series,
        // every series will auto-map to each column by default.
        dataZoom: [
          {
            id: 'dataZoomX',
            type: 'slider',
            xAxisIndex: [0],
            filterMode: 'empty',
            showDataShadow: false,
          },
          // {
          //   id: 'dataZoomY',
          //   type: 'slider',
          //   yAxisIndex: [1],
          //   filterMode: 'none'
          // },
          // {
          //   type: 'inside',
          //   yAxisIndex: [0],
          //   filterMode: 'none'
          // }
        ],

        series: o,
      };
    }
  }
  changeKumulierteDarstellung(e: any) {
    this.settingService.setKumuliertSubject$(e);
    this.createChartOptions();
  }

  changeBetrachtungsZeitraum(e: any) {
    this.settingService.setRangeSubject$(e);
    this.createChartOptions();
  }
}
