import {Component, OnInit, ViewChild} from '@angular/core';
import {ProductDetailService} from '../../../services/product-detail-service.service';
import {ActivatedRoute} from '@angular/router';
import { NgbActiveModal, NgbNav, NgbNavItem, NgbNavLinkButton, NgbNavContent, NgbNavOutlet } from '@ng-bootstrap/ng-bootstrap';
import {Product, ProductKpiDTO} from '../../../domain/product.model';
import {ProductVariant} from '../../../domain/productDetail.model';
import { DxDataGridComponent, DxDataGridModule } from 'devextreme-angular';
import { DxoSortingModule, DxoSelectionModule, DxoFilterRowModule, DxoScrollingModule, DxoPagingModule, DxiColumnModule, DxoSummaryModule, DxiTotalItemModule } from 'devextreme-angular/ui/nested';
import { DxTemplateModule } from 'devextreme-angular/core';
import { ProductPartsComponent } from '../product-parts/product-parts.component';
import { TransferLinesComponent } from '../transfer-lines/transfer-lines.component';
import { PurchaseLinesComponent } from '../purchase-lines/purchase-lines.component';
import { AssemblyProductComponent } from '../assembly-product/assembly-product.component';

@Component({
    selector: 'app-product-detail',
    templateUrl: './product-detail.component.html',
    styleUrls: ['./product-detail.component.scss'],
    standalone: true,
    imports: [DxDataGridModule, DxoSortingModule, DxoSelectionModule, DxoFilterRowModule, DxoScrollingModule, DxoPagingModule, DxiColumnModule, DxTemplateModule, DxoSummaryModule, DxiTotalItemModule, NgbNav, NgbNavItem, NgbNavLinkButton, NgbNavContent, ProductPartsComponent, TransferLinesComponent, PurchaseLinesComponent, AssemblyProductComponent, NgbNavOutlet]
})
export class ProductDetailComponent implements OnInit{
  variants: ProductVariant[] = [];
  product!:ProductKpiDTO;
  public selectedVariant!: ProductVariant;


  // @ts-ignore
  @ViewChild(DxDataGridComponent) variantgrid: DxDataGridComponent;

  constructor(private productDetailService: ProductDetailService,private activatedRoute: ActivatedRoute,public modal: NgbActiveModal,) {
  }

  ngOnInit(): void {
    //handles routing
    // this.activatedRoute.params.subscribe((params) => {
    //   if (params['pid']) {
    //     this.productDetailService.getVariantst(params['pid']).subscribe(value => {
    //       this.variants = value;
    //     })
    //   }})
        this.productDetailService.getVariantst(this.product.productId).subscribe(value => {
          this.variants = value;
          if(value.length>0)
          this.variantgrid.instance.selectRows(value[0]['item'], false)
        })

  }

  selectionChanged(e: any) {
    if(e.selectedRowKeys.length===1){
      this.selectedVariant=e.selectedRowsData[0];
    }
  }

  routeToNav(data: any) {
    //
  }

  stockMinusNewOrders(rowData: any) {
    return rowData.amountInStock - rowData.amountReservedNewOrders;
  }
}
