import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  roles: BehaviorSubject<string[]> = new BehaviorSubject(['']);
  constructor() {}
}
