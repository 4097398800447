import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {AdvertisingMedium} from '../../../domain/advertisingMedium';
import {KpiService} from '../../../services/kpi.service';
import {KpiChartDTO} from '../../../domain/kpi.mpodel';
import {formatCurrency, NgFor, NgIf} from '@angular/common';
import {NgxEchartsDirective, NgxEchartsModule, provideEcharts} from 'ngx-echarts';
import {FormsModule} from '@angular/forms';

@Component({
    selector: 'am-bar-chart-compare',
    templateUrl: './am-bar-chart-compare.component.html',
    styleUrls: ['./am-bar-chart-compare.component.scss'],
    standalone: true,
  imports: [
    NgxEchartsDirective,
    NgIf,
  ],
  providers: [
    provideEcharts(),
  ]
})
export class AmBarChartCompareComponent implements OnChanges{
  @Input() kpiChartDTO: KpiChartDTO | undefined;

  public kpiOptions:any;
  public ready = false;
  constructor(private kpiService: KpiService) {
  }

  ngOnChanges(changes: SimpleChanges): void {

    const chartDTO: KpiChartDTO = changes['kpiChartDTO'].currentValue;
    if (chartDTO) {
        const head: any[] =chartDTO.xaxisData;
        head.unshift('data')
        const data=[];
        const seriesLayout: any[]=[];
        data.push(head);

        chartDTO.kpiSeries.forEach(serie => {
          const d: any=serie.data;
          d.unshift(serie.name);
          data.push(d)
          seriesLayout.push({
            type: 'bar',
            barGap: .2,
            //label: labelOption,
            emphasis: {
              focus: 'series'
            }});
        })





// Example for label in bar
        // const labelOption = {
        //   show: true,
        //   rotate: 90,
        //   align: 'left',
        //   verticalAlign: 'middle',
        //   position: 'insideBottom',
        //   distance: 15,
        //   formatter: '{c}  {name|{a}}',
        //   fontSize: 16,
        //   rich: {
        //     name: {}
        //   }
        // };
        this.kpiOptions = {
          legend: {},
          tooltip: {
            trigger: 'axis',
            valueFormatter: (value: number) =>  formatCurrency(value,'de','€', 'EUR','2.0-0') ,
            axisPointer: {
              type: 'shadow'
            }
          },
          toolbox: {
            show: true,
            orient: 'vertical',
            left: 'right',
            top: 'center',
            feature: {
              mark: { show: true },
              dataView: { show: true, readOnly: true },
              restore: { show: true },
              saveAsImage: { show: true }
            }
          },
          dataset: {
            // Provide a set of data.
            source: data
          },
          // Declare an x-axis (category axis).
          // The category map the first column in the dataset by default.
          xAxis: { type: 'category' },
          // Declare a y-axis (value axis).
          yAxis: {
            axisLabel: {
              formatter: '{value} €',
              align: 'center'
            }
          },
          // Declare several 'bar' series,
          // every series will auto-map to each column by default.
          series: seriesLayout
        };
        this.ready=true;

    }
  }



}
