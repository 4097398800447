import {Component, Input, OnChanges, SimpleChanges, ViewChild} from '@angular/core';
import {ProductVariant} from '../../../domain/productDetail.model';
import { DxDataGridComponent, DxDataGridModule } from 'devextreme-angular';
import DataSource from 'devextreme/data/data_source';
import {ProductDetailService} from '../../../services/product-detail-service.service';
import {ActivatedRoute} from '@angular/router';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { DxiColumnModule, DxoSortingModule, DxoSelectionModule, DxoFilterRowModule, DxoScrollingModule, DxoPagingModule } from 'devextreme-angular/ui/nested';

@Component({
    selector: 'app-transfer-lines',
    templateUrl: './transfer-lines.component.html',
    styleUrls: ['./transfer-lines.component.scss'],
    standalone: true,
    imports: [DxDataGridModule, DxiColumnModule, DxoSortingModule, DxoSelectionModule, DxoFilterRowModule, DxoScrollingModule, DxoPagingModule]
})
export class TransferLinesComponent  implements OnChanges {
  @Input()selectedVariant!: ProductVariant;

  // @ts-ignore
  @ViewChild(DxDataGridComponent) transfergrid: DxDataGridComponent;

  // @ts-ignore
  ds:DataSource;
  constructor(private productDetailService: ProductDetailService,private activatedRoute: ActivatedRoute,public modal: NgbActiveModal,) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['selectedVariant']) this.selectedVariant = changes['selectedVariant'].currentValue;

    this.productDetailService.getTransfers(this.selectedVariant.item).subscribe(value => {
      this.ds = new DataSource({
        store: value
      });
      this.transfergrid.instance.repaint()

    })
  }

}
