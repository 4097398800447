<div role="status">
  <div *ngIf="status && status.type != 'nope'" class="alert" [ngClass]="'alert-' + status.type + ' ' + customStyle">
    <span [innerHTML]="status.message">{{ status.message }}</span>
  </div>
</div>

<div id="error" tabindex="-1">
  <div *ngIf="error" class="alert alert-danger" [ngClass]="customStyle">
    <span [innerHTML]="error">{{ error }}</span>
  </div>
</div>
