<div class="modal-header">
  <h1 class="modal-title" id="confirm-modal-label">{{ title }}</h1>
</div>
<div class="modal-body" id="confirm-modal-body">
  <span [innerHTML]="text">{{ text }}</span>
</div>
<div class="modal-footer">
  <button *ngIf="!confirmOnly" type="button" class="btn btn-sm btn-outline-info" id="modal-cancel"
          aria-describedby="confirm-modal-label confirm-modal-body" autofocus
          (click)="cancel()">
    {{ cancelButton }}
  </button>
  <button type="button" class="btn btn-sm btn-outline-info" id="modal-confirm"
          [attr.aria-describedby]="confirmOnly ? 'confirm-modal-label confirm-modal-body' : undefined"
          (click)="confirm()">
    {{ confirmButton }}
  </button>
</div>
