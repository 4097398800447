import {Component, ViewChild} from '@angular/core';
import { DxDataGridComponent, DxDataGridModule } from "devextreme-angular";
import DataSource from "devextreme/data/data_source";
import {lastValueFrom} from "rxjs";
import {LoadingStandService} from "../../services/loading-stand.service";
import {LoadingStand} from "../../domain/loadingStand.model";
import {NotifyService} from '../../services/notify.service';
import { DxoSortingModule, DxoSelectionModule, DxoFilterRowModule, DxoHeaderFilterModule, DxoFilterPanelModule, DxoSearchPanelModule, DxoColumnChooserModule, DxoStateStoringModule, DxoPagingModule, DxoPagerModule, DxiColumnModule } from 'devextreme-angular/ui/nested';

@Component({
    selector: 'app-loading-stand',
    templateUrl: './loading-stand.component.html',
    styleUrls: ['./loading-stand.component.scss'],
    standalone: true,
    imports: [DxDataGridModule, DxoSortingModule, DxoSelectionModule, DxoFilterRowModule, DxoHeaderFilterModule, DxoFilterPanelModule, DxoSearchPanelModule, DxoColumnChooserModule, DxoStateStoringModule, DxoPagingModule, DxoPagerModule, DxiColumnModule]
})
export class LoadingStandComponent {

  // @ts-ignore
  @ViewChild(DxDataGridComponent) loadingStandGrid: DxDataGridComponent;
  public dataSource: DataSource;

  constructor(private loadingStandService: LoadingStandService,  private notifyService : NotifyService,) {
    this.dataSource = new DataSource({
      key: 'id',
      load: () => {
        return lastValueFrom(this.loadingStandService.getLoadingStands()).then((loadingStands: LoadingStand[]) => {
          return {
            data:loadingStands,
            totalCount: loadingStands.length
          };
        }).catch((err) => {
          throw Error(err.message);
        });
      }
    })
  }

  onCellPrepared(e: any) {
    if (e.rowType === "data") {
      if (e.column.dataField === "errorMessage" && e.data.errorMessage != null ) {
        e.cellElement.style.cssText = "color: white; background-color: red";
      }
    }
  }

  triggerLoading() {
    this.loadingStandService.getTriggerLoading().subscribe(value => {
      this.notifyService.toastInfo('Datenladen wurde asynchron gestartet.')
    })
  }
}
