import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import {Observable} from "rxjs";
import {LoadingStand} from "../domain/loadingStand.model";

@Injectable({providedIn: 'root'})
export class LoadingStandService {

  constructor(private httpClient: HttpClient) {}

  getLoadingStands(): Observable<LoadingStand[]> {
    return this.httpClient.get<LoadingStand[]>(`api/loading_stands`);
  }


  getTriggerLoading(): Observable<any> {
    return this.httpClient.get<any>(`api/trigger-loading`);
  }
}
