import {AdvertisingMedium} from './advertisingMedium';


export interface KpiChartDTO {
  advertisingMedium: AdvertisingMedium;
  kpiName: string;
  xaxisData: any[];
  kpiSeries: KpiSerie[];

}

export interface KpiSerie {
  name: string;
  data: number[];
}

export interface KpiLineChartDTO {
  advertisingMedium: AdvertisingMedium;
  kpiName: string;
  kpiSeries: KpiLineSerie[];
}

export interface KpiLineSerie  {
  name: string;
  type: 'lines';
  seriesLayoutBy: 'row';
  smooth: number;
  data: any[];
  lineStyle?: LineStyle;
  symbol?: string;
  color?: string;
}

export interface LineStyle  {

  width: number;
  type: string;
}


export enum KpiPerspective{
  OVERVIEW='OVERVIEW',
  ORDER_CHANNEL='ORDER_CHANNEL'
}

export enum IntakeChannel{
  ALL='ALL',
  WEB='WEB',
  PER='PER',
  TEL='TEL'
}


export interface KpiVal{
 datum: Date;
 dimensionswert: string;
 umsatz: number;
}

export enum color {
 'dark_red'= '#c1232b',
 'dark_green'= '#27727b',
 'orange'=  '#fcce10',
 'brown'= '#e87c25',
 'olive'=  '#b5c334',
 'rose'=  '#fe8463',
 'green'=  '#9bca63',
 'yellow'=  '#fad860',
 'sand'=  '#f3a43b',
 'blue'=  '#60c0dd',
 'red'='#d7504b',
 'light_green'='#c6e579',
 'light_yellow'='#f4e001',
 'turkis'='#26c0c0'
}

export interface AdvertisingMediumKpi{
  id: AdvertisingMediumKpiId;
  orderIntake: number;
  totalOrderIntake: number;
}

export interface AdvertisingMediumKpiId{
  advertisingMediumId: number;
  kpiDate: Date;
}

