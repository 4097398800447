import { Injectable } from '@angular/core';
import {Router, NavigationEnd, RoutesRecognized} from '@angular/router';
import {filter, pairwise} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PreviousRouteService {
  // @ts-ignore
  private previousUrl: string;
  private currentUrl: string;

  constructor(private router: Router) {
    this.currentUrl = this.router.url;
    this.router.events
      .pipe(filter((evt: any) => evt instanceof RoutesRecognized), pairwise())
      .subscribe((events: RoutesRecognized[]) => {
        this.previousUrl = events[0].urlAfterRedirects;
        this.currentUrl = events[1].urlAfterRedirects;
      });
  }



  public getPreviousUrl() {
    return this.previousUrl;
  }
}
