<h1 class="display-6">Ladestand</h1>
<dx-data-grid
  id="loadingStandGrid"
  [dataSource]="dataSource"
  [showBorders]="true"
  [allowColumnResizing]="true"
  [allowColumnReordering]="true"
  [columnAutoWidth]="true"
  (onCellPrepared)="onCellPrepared($event)"
>
  <dxo-sorting mode="single"></dxo-sorting>
  <dxo-selection mode="single"></dxo-selection>
  <dxo-filter-row [visible]="true"></dxo-filter-row>
  <dxo-header-filter [visible]="true"></dxo-header-filter>
  <dxo-filter-panel [visible]="true"></dxo-filter-panel>
  <dxo-search-panel
    [visible]="true"
    [width]="240"
    placeholder="Suche..."
  ></dxo-search-panel>
  <dxo-column-chooser [enabled]="true"></dxo-column-chooser>
  <dxo-state-storing [enabled]="true" storageKey="loadingStandGrid" type="sessionStorage"></dxo-state-storing>

  <dxo-paging [pageSize]="8"></dxo-paging>
  <dxo-pager
    [showPageSizeSelector]="true"
    [allowedPageSizes]="[8, 10, 15]"
  ></dxo-pager>

  <dxi-column
    dataField="id"
    caption="Id"
  >
  </dxi-column>

  <dxi-column
    dataField="loadingDate"
    caption="Lade Datum"
    dataType="date"
    [format]="'dd.MM.yyyy'"
  >
  </dxi-column>

  <dxi-column
    dataField="successful"
    caption="Erfolgreich"
  >
  </dxi-column>

  <dxi-column
    dataField="errorMessage"
    caption="Fehler Meldung"
  >
  </dxi-column>

  <dxi-column
    dataField="loadedEntity"
    caption="Instanz"
  >
  </dxi-column>

</dx-data-grid>

<button (click)="triggerLoading()">start loading</button>
